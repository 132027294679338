import React from 'react';
import { motion, MotionProps } from 'framer-motion';
import classNames from 'classnames';
import styles from './index.module.scss';

interface MotionTextProps extends MotionProps {
  as?: keyof JSX.IntrinsicElements; // Allows h1, h2, p, span, etc.
  className?: string;
  text: React.ReactNode | string;
}

export const MotionText: React.FC<MotionTextProps> = ({
  as: Component = 'p',
  className = '',
  text,
  initial = {},
  animate = {},
  exit = {},
  transition = {},
  ...rest
}) => (
  <motion.div
    initial={initial}
    animate={animate}
    exit={exit}
    transition={transition}
    className={classNames(styles.textStyles, className)}
    {...rest}
  >
    <Component className={classNames(styles.textElement, className)}>{text}</Component>{' '}
  </motion.div>
);

MotionText.defaultProps = {
  as: 'p',
  className: classNames(styles.textStyles),
};
