import {
  QuestionnaireCategoryInterface,
  assessmentQuestionnaireApiHandler,
} from '@api/assessmentQuestionnaireApiHandler';
import { smoothScrollTo } from '@helpers/general.helpers';
import { useAppSelector } from '@hooks/useAppSelector';
import { ApiResponse } from '@interfaces/index';
import { SanitizedText, showToast } from '@shared/atoms';
import { ColoredBox, SelectableArea, SystemButton } from '@shared/molecules';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TALK_TO_ADVISOR_AREAS_DISCUSS_MESSAGE } from 'custom.d';
import React, { useEffect, useState } from 'react';
import { RootState } from 'store';
import styles from './index.module.scss';

export const TalkToAdviserCategorySelection: React.FunctionComponent<
  TalkToAdviserCategorySelectionProps
> = (props) => {
  const { handleSkip } = props;
  const companyConfig = useAppSelector((state: RootState) => state.companyConfig.data);
  const [step, handleStep] = useState<
    'talkToAdvisorMessage' | 'talkToAdvisorDiscussion' | 'talkToAdvisorCompletion'
  >('talkToAdvisorMessage');

  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);
  const [noSelection, setNoSelection] = useState(true);
  const user = localStorage.getItem('user');
  const { chosenQuestionnaire, createdAssessmentDetails } = useAppSelector(
    (state) => state.questionnaireV2 || {},
  );
  const [categories, setCategories] = useState<QuestionnaireCategoryInterface[]>([]);

  const useSubmitTalkToAdvisor = () =>
    useMutation({
      mutationFn: ({
        assessmentId,
        categoryIds,
      }: {
        assessmentId: number;
        categoryIds: number[];
      }) => {
        const payload = {
          assessmentId,
          categoryIds,
        };

        return assessmentQuestionnaireApiHandler.createTalkToAdvisor(payload);
      },
    });

  const submitTalkToAdvisorMutation = useSubmitTalkToAdvisor();

  const fetchQuestionnaireCategories = async (): Promise<
    ApiResponse<QuestionnaireCategoryInterface[]> | undefined
  > => {
    if (!chosenQuestionnaire || !chosenQuestionnaire.questionnaireId) {
      return undefined;
    }

    return assessmentQuestionnaireApiHandler.getQuestionnaireCategories(chosenQuestionnaire?.id);
  };

  const {
    data: categoriesData,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useQuery({
    queryKey: ['fetchQuestionnaireCategories', chosenQuestionnaire?.questionnaireId],
    queryFn: fetchQuestionnaireCategories,
    enabled: !!(chosenQuestionnaire && chosenQuestionnaire?.questionnaireId),
  });

  useEffect(() => {
    if (categoriesData?.success) {
      setCategories(categoriesData?.responseObject || []);
    } else {
      setCategories([]);
    }
  }, [categoriesData]);

  const handleSelectFocus = (id: number) => {
    setSelectedCategoryIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        const newSelectedIds = prevSelectedIds.filter((selectedId) => selectedId !== id);
        setNoSelection(newSelectedIds.length === 0);

        return newSelectedIds;
      }

      if (prevSelectedIds.length < 3) {
        const newSelectedIds = [...prevSelectedIds, id];
        setNoSelection(false);

        return newSelectedIds;
      }

      return prevSelectedIds;
    });
  };

  const handleSubmitAdvisor = () => {
    if (createdAssessmentDetails) {
      const payload = {
        assessmentId: createdAssessmentDetails?.id,
        categoryIds: selectedCategoryIds,
      };
      submitTalkToAdvisorMutation.mutate(payload, {
        onSuccess: (response) => {
          if (response?.success) {
            handleStep('talkToAdvisorCompletion');
          } else {
            showToast('Error occurred. Try again', undefined, 'error');
          }
        },
        onError: (error) => {
          showToast('Error occurred. Try again', undefined, 'error');
        },
      });
    } else {
      showToast('Questionnaire not selected', undefined, 'error');
    }
  };

  return (
    <div className={styles.tasContainer}>
      {(() => {
        switch (step) {
          case 'talkToAdvisorMessage':
            smoothScrollTo(0, 0);

            return (
              <div>
                <div className={styles.actionContainer}>
                  <div className={styles.cardContainer}>
                    {companyConfig.talkToAdvisor && (
                      <>
                        {companyConfig.talkToAdvisorMessage && (
                          <SanitizedText as="div" content={companyConfig.talkToAdvisorMessage} />
                        )}

                        <div className={styles.navigateBtnContainer}>
                          <SystemButton variant="outlinedPrimarySkyBlue" onClick={handleSkip}>
                            No, skip this process
                          </SystemButton>
                          <SystemButton
                            variant="primarySkyBlue"
                            onClick={() => handleStep('talkToAdvisorDiscussion')}
                          >
                            Yes, I&apos;d like to discuss
                          </SystemButton>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );

          case 'talkToAdvisorDiscussion':
            return (
              <div>
                <div className={styles.actionContainer}>
                  <div className={styles.cardContainer}>
                    {companyConfig.talkToAdvisor && (
                      <>
                        {TALK_TO_ADVISOR_AREAS_DISCUSS_MESSAGE && (
                          <SanitizedText as="div" content={TALK_TO_ADVISOR_AREAS_DISCUSS_MESSAGE} />
                        )}
                      </>
                    )}
                  </div>
                  <div className={styles.coloredBoxContainer}>
                    <div className={styles.coloredBox}>
                      <ColoredBox
                        title="Select the areas you would like to discuss"
                        isTitleCentered
                        color="blue"
                      >
                        <div className={styles.focusContainer}>
                          {categories?.length > 0 &&
                            categories?.map((dt) => (
                              <div key={dt.id} className={styles.hsCategoryContainer}>
                                <SelectableArea
                                  key={dt.id}
                                  id={dt.id}
                                  name={dt.name}
                                  color={undefined}
                                  score={undefined}
                                  isSelected={selectedCategoryIds.includes(dt.id)}
                                  onSelect={() => handleSelectFocus(dt.id)}
                                />
                              </div>
                            ))}
                        </div>
                        <div className={`${styles.footerImage} ${styles.footerContainer}`} />
                      </ColoredBox>
                    </div>

                    <div className={styles.navigateBtnContainer}>
                      <SystemButton
                        variant="primarySkyBlue"
                        disabled={selectedCategoryIds?.length < 1}
                        onClick={handleSubmitAdvisor}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Continue
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </SystemButton>
                    </div>
                  </div>
                </div>
              </div>
            );

          case 'talkToAdvisorCompletion':
            smoothScrollTo(0, 0);

            return (
              <div>
                <div className={styles.actionContainer}>
                  <div className={styles.cardContainer}>
                    {companyConfig.talkToAdvisor && companyConfig.talkToAdvisorCompleteMessage && (
                      <>
                        <SanitizedText
                          as="div"
                          content={companyConfig.talkToAdvisorCompleteMessage}
                        />

                        <div className={styles.centeredButton}>
                          <SystemButton variant="primarySkyBlue" onClick={handleSkip}>
                            {' '}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Continue
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </SystemButton>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );

          default:
            return null;
        }
      })()}
    </div>
  );
};

TalkToAdviserCategorySelection.defaultProps = {
  handleSkip: () => null,
};

interface TalkToAdviserCategorySelectionProps {
  handleSkip?: () => void;
}
