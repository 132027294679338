import useWindowDimensions from '@hooks/useWindowDimensions';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React from 'react';

interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  dialogStyles?: React.CSSProperties;
  contentStyles?: React.CSSProperties;
  showMobileBorderRadius?: boolean;
  showCloseButton?: boolean;
}

export const CustomDialog: React.FC<CustomDialogProps> = ({
  open,
  onClose,
  title,
  children,
  actions,
  dialogStyles,
  contentStyles,
  showMobileBorderRadius = true,
  showCloseButton = true,
}) => {
  const { isMobile } = useWindowDimensions();

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: isMobile && !showMobileBorderRadius ? 0 : 10,
          zIndex: 9999999,
          ...dialogStyles,
        },
      }}
    >
      {(title || showCloseButton) && (
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {title}
          {showCloseButton && (
            <IconButton onClick={onClose} sx={{ ml: 'auto' }}>
              Close <CloseIcon fontSize="large" />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent sx={contentStyles}>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

CustomDialog.defaultProps = {
  showMobileBorderRadius: true,
  showCloseButton: true,
  dialogStyles: {},
  contentStyles: {},
  title: undefined,
  actions: undefined,
};
