import { TalkToAdvisorRequestsResponseData, advisorApiHandler } from '@api/advisorApiHandler';
import { ApiResponse } from '@interfaces/index';
import { useQuery } from '@tanstack/react-query';

export const useGetTalkToAdvisorRequests = (startDate: string, endDate: string, enabled: boolean) =>
  useQuery<ApiResponse<TalkToAdvisorRequestsResponseData>>({
    queryKey: ['adminTalkToAdvisorRequests', startDate, endDate],
    queryFn: () => advisorApiHandler.getAdminTalkToAdvisorRequests({ startDate, endDate }),
    enabled,
  });
