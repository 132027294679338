import { getShortStarQuestionsData } from '@api/shortStar';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { Grid, Skeleton } from '@mui/material';
import classNames from 'classnames';
import { ShortStarQuestionsInterface } from 'custom';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styles from './index.module.scss';

export const WellbeingPillarsSection: React.FunctionComponent<WellbeingPillarsSectionProps> = ({
  loading,
}) => {
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const [starQuestionsDataLoading, setStarQuestionsDataLoading] = useState(true);
  const [starQuestions, setStarQuestions] = useState<ShortStarQuestionsInterface[] | []>([]);
  const { isDesktop } = useWindowDimensions();

  useEffect(() => {
    getStarQuestions();
  }, []);

  const getStarQuestions = async () => {
    try {
      setStarQuestionsDataLoading(true);
      const response = await getShortStarQuestionsData();
      const updatedResponse = response.map((dt) => ({
        ...dt,
        icon: `../../../../../images/shortStar/${dt.icon}.svg`,
      }));
      setStarQuestions(updatedResponse);
    } catch (error) {
      setStarQuestions([]);
    } finally {
      setStarQuestionsDataLoading(false);
    }
  };

  const navigate = (questionId: number, questionName: string) => {
    urlParams.set('type', 'questionContent');
    if (urlParams.has('questionId')) {
      urlParams.set('questionId', questionId.toString());
    } else {
      urlParams.append('questionId', questionId.toString());
    }

    if (urlParams.has('questionName')) {
      urlParams.set('questionName', questionName);
    } else {
      urlParams.append('questionName', questionName);
    }

    history.push({ pathname: '/posts', search: urlParams.toString() });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h5>Core wellbeing pillars</h5>
      </div>

      <Grid container spacing={isDesktop ? 3 : 1}>
        {starQuestionsDataLoading
          ? [0, 1, 2, 3, 4, 5].map((index) => (
              <Grid item xs={4} md={2} key={index}>
                <Skeleton variant="rounded" height={142} className={styles.loadingItem} />
              </Grid>
            ))
          : starQuestions.map((question) => (
              <Grid
                item
                xs={4}
                md={2}
                key={question.questionId}
                className={styles.questionContainer}
              >
                <div
                  className={classNames(styles.roundedSquareButton, styles[question.colorTheme])}
                  onClick={() => navigate(question.questionId, question.questionText)}
                >
                  <div className={styles.questionText}>{question.questionText} </div>
                  <div className={styles.questionText}>Wellbeing</div>
                  <div className={styles.questionIcon}>
                    <img src={question.icon} alt={question.questionText} />
                  </div>
                </div>
              </Grid>
            ))}
      </Grid>
    </div>
  );
};

WellbeingPillarsSection.defaultProps = {
  loading: false,
};

interface WellbeingPillarsSectionProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  loading?: boolean;
}
