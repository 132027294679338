import {
  CompanyQuestionnaireDataInterface,
  QuestionnaireInterface,
  assessmentQuestionnaireApiHandler,
} from '@api/assessmentQuestionnaireApiHandler';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { ApiResponse } from '@interfaces/index';
import { showToast } from '@shared/atoms';
import { SingleQuestionnaire } from '@shared/organisms';
import { CoreLayout } from '@shared/templates';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';

export const QuestionnairePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isDesktop } = useWindowDimensions();
  const company = localStorage.getItem('company');
  const parsedCompany = JSON.parse(company || '{}');
  const { chosenQuestionnaire } = useAppSelector((state) => state.questionnaireV2 || {});

  useEffect(() => {
    showToast(
      'Welcome to the whole new experience of Hapstar V2.0 (New toast view)',
      undefined,
      'success',
    );
  }, []);

  const fetchQuestionnaires = async (): Promise<ApiResponse<CompanyQuestionnaireDataInterface[]>> =>
    assessmentQuestionnaireApiHandler.getAllCompanyQuestionnaires(parsedCompany.id);

  const fetchQuestionsByQuestionnaire = async (): Promise<
    ApiResponse<QuestionnaireInterface> | undefined
  > => {
    if (!chosenQuestionnaire || !chosenQuestionnaire.questionnaireId) {
      showToast('Please select a questionnaire from the list', undefined, 'error');

      return undefined;
    }

    return assessmentQuestionnaireApiHandler.getQuestionsByQuestionnaire(
      chosenQuestionnaire.questionnaireId,
    );
  };

  const {
    data: questionnairesData,
    isLoading: questionnairesLoading,
    error: questionnairesError,
  } = useQuery({
    queryKey: ['getAllCompanyQuestionnaires', parsedCompany.id],
    queryFn: fetchQuestionnaires,
    enabled: !!parsedCompany?.id,
  });

  const {
    data: questionsData,
    isLoading: questionsLoading,
    error: questionsError,
  } = useQuery({
    queryKey: ['getQuestionsByQuestionnaire', chosenQuestionnaire?.questionnaireId],
    queryFn: fetchQuestionsByQuestionnaire,
    enabled: !!(parsedCompany?.id && chosenQuestionnaire && chosenQuestionnaire?.questionnaireId),
  });

  const questionnaires = questionnairesData?.success ? questionnairesData?.responseObject : [];

  return (
    <CoreLayout title="Assessment Page" navigationType="default">
      <SingleQuestionnaire
        companyQuestionnaires={questionnaires}
        questionsData={questionsData}
        questionnairesLoading={questionnairesLoading}
        questionnairesError={questionnairesError}
        questionsLoading={questionsLoading}
        questionsError={questionsError}
      />
    </CoreLayout>
  );
};
