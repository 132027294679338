import { ApiResponse } from '@interfaces/index';
import createApiClient from './configs/httpHandler';

export const advisorApiHandler = {
  getAdminTalkToAdvisorRequests: async (
    data: Partial<TalkToAdvisorRequestsPayload>,
  ): Promise<ApiResponse<TalkToAdvisorRequestsResponseData>> =>
    createApiClient().post('/company-talk-to-advisor-requests', data),
};

export interface TalkToAdvisorRequestsPayload {
  startDate: string;
  endDate: string;
}

interface Header {
  key: string;
  label: string | null;
}

interface DataItem {
  participantId: string;
  requestedDate: string;
  area: string;
  focusAreas: string[];
  jobLocation: string | null;
  team: string | null;
  jobTitle: string | null;
  jobType: string | null;
}

export interface TalkToAdvisorRequestsResponseData {
  headers: Header[];
  data: DataItem[];
}
