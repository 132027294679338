import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';

type LoadingDotsProps = {
  size?: number;
  dotCount?: number;
  marginTop?: string | number;
  marginBottom?: string | number;
  isHapstarLoading?: boolean;
  color?: string;
};

const colors = ['#019df2', '#beaaf3', '#fcd300', '#8eea36', '#06bf97', '#ff8a4a'];

export const LoadingDots: React.FC<LoadingDotsProps> = ({
  size = 8,
  dotCount = 3,
  marginTop = 0,
  marginBottom = 0,
  isHapstarLoading = false,
  color = '#007bff',
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      marginTop,
      marginBottom,
    }}
  >
    {[...Array(dotCount)].map((_, i) => (
      <motion.span
        key={i}
        animate={{
          opacity: [0.4, 1, 0.4],
          y: [0, -5, 0],
          backgroundColor: isHapstarLoading ? colors : color,
        }}
        transition={{
          duration: 1.2,
          repeat: Infinity,
          ease: 'easeInOut',
          delay: i * 0.2,
          backgroundColor: isHapstarLoading
            ? { repeat: Infinity, duration: 2.4, ease: 'easeInOut' }
            : undefined,
        }}
        style={{
          width: size,
          height: size,
          margin: '0 4px',
          borderRadius: '50%',
          display: 'inline-block',
          backgroundColor: isHapstarLoading ? undefined : color,
        }}
      />
    ))}
  </Box>
);

LoadingDots.defaultProps = {
  color: '#007bff',
  size: 8,
  dotCount: 3,
  marginTop: 0,
  marginBottom: 0,
  isHapstarLoading: false,
};
