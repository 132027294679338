import { getToken } from '@api/config';
import { UpdateUserStatusPayload } from '@api/userStatusApiHandler';
import Card from '@components/Atoms/Card';
import DefaultButton from '@components/Atoms/DefaultButton';
import Modal from '@components/Atoms/Modal';
import FormButtonNew from '@components/FormButtonNew/FormButtonNew';
import InstantHelp from '@components/Organisms/InstantHelp';
import { getCompanyDetails } from '@helpers/auth.helper';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import AuthenticationLayout from '@layouts/AuthenticationLayout';
import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { PublicCompanyDetails } from 'custom.d';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { RootState } from 'store';
import { getInstantHelpData } from 'store/Actions/InstantHelpAction';
import { updateUserStatusData } from 'store/Actions/UserStatusAction';
import styles from './index.module.scss';

const IntroductionPage: React.FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [getHelp, setGetHelp] = useState<boolean>(false);
  const instantHelpData = useAppSelector((state: RootState) => state.instantHelp);
  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig);
  const [loading, setLoading] = useState(false);
  const [companyDetails, setCompanyDetails] = useState<PublicCompanyDetails | null>(null);
  const token = getToken();
  let organizationName;
  let organizationLogo;

  useEffect(() => {
    const getCompanyData = async () => {
      const data = await getCompanyDetails();
      if (data) {
        const compDetails = {
          companyLogo: data.company_logo,
          companyName: data.company_name,
          companyUrl: data.company_url,
          hideHapstarLogo: false,
          id: data.id,
          signUpText: data.sign_up_text,
          loginText: data.login_text,
          shareThoughts: data.share_thoughts,
          platformName: null,
        };
        setCompanyDetails(compDetails);
      }
    };

    if (token && token !== null && token !== undefined) {
      getCompanyData();
    }
  }, [token]);

  useEffect(() => {
    async function getInstantData() {
      await setLoading(true);
      await dispatch(getInstantHelpData());

      setLoading(false);
    }

    if (!instantHelpData.isLoaded) {
      getInstantData();
    }
  }, [dispatch, instantHelpData.isLoaded]);

  const updateUserStatus = async () => {
    const data: UpdateUserStatusPayload = {
      isRegistered: true,
      isJoined: true,
      isEligibleForSpeakUpAlerts: null,
      isOnboardCompleted: null,
      isFirstShortStarCompleted: null,
    };

    dispatch(updateUserStatusData(data));

    history.push('/');
  };

  if (companyDetails !== null && companyDetails.id !== 0) {
    organizationName = companyDetails.companyName && companyDetails.companyName;
    organizationLogo = companyDetails.companyLogo
      ? `${process.env.PUBLIC_URL}/images/logos/${companyDetails.companyLogo}`
      : undefined;
  }

  return (
    <div className={styles.hsIntroPageContainer}>
      <AuthenticationLayout
        title="Introduction"
        organizationLogo={organizationLogo}
        organizationAltText={organizationName}
        hideHapstarLogoEnabled={companyConfigData.data.hideHapstarLogo}
      >
        <div className={styles.hsIntroPageSubContainer}>
          <Card>
            <div className={styles.hsIntroCardContainer}>
              <h1>
                <span>Getting started is easy.</span>
              </h1>
              <p>
                Following the Hapstar process of ‘score’, ‘review’, ‘improve’, you will work on
                managing and influencing different areas of your life, health and wellbeing. Hapstar
                is all about taking that next step towards happiness. Let’s start with the first, by
                clicking ‘continue’.
              </p>
              <p>
                If you are in need of emergency support, this platform may not be appropriate for
                you.
              </p>
              <p>
                We have compiled a list of{' '}
                <span
                  onClick={() => {
                    if (companyConfigData?.data && companyConfigData?.data?.showInstantHelp) {
                      setGetHelp(true);
                    }
                  }}
                >
                  emergency resources
                </span>{' '}
                providing access to immediate support if you feel for your safety, or the safety of
                those around you – is at risk. If this applies to you, we strongly recommend that
                you contact your medical provider and notify them of your circumstances.
              </p>
              <p>If this does not apply to you, please continue.</p>
              <p>So, let’s begin</p>
              <FormButtonNew onClick={updateUserStatus} color="primary">
                Continue
              </FormButtonNew>
            </div>
          </Card>
        </div>

        {getHelp && (
          <div className="container">
            <Modal
              open={getHelp}
              setOpen={setGetHelp}
              onTapBackgroundClose
              className={styles.modalRoot}
              overlay
            >
              <DefaultButton
                color="primary"
                type="button"
                className={styles.btnClose}
                onClick={() => setGetHelp(false)}
              >
                <div className={styles.btnCloseLabel}>Close</div>
                <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
              </DefaultButton>
              {instantHelpData.error && !loading && <p>Unable to fetch wellbeing data</p>}

              {loading && (
                <div className="container">
                  <div className={styles.loadingContainer}>
                    <Skeleton variant="rounded" width="50%" height={50} />

                    <div className={styles.loadingDesc}>
                      <Skeleton variant="rounded" width="100%" height={20} />
                      <Skeleton variant="rounded" width="100%" height={20} />
                      <Skeleton variant="rounded" width="100%" height={20} />
                    </div>
                    <Skeleton variant="rounded" width="100%" height="100vh" />
                  </div>
                </div>
              )}

              {!instantHelpData.loading && !loading && (
                <InstantHelp
                  title="Wellbeing Hub"
                  subText={companyConfigData?.data?.instantHelpDescription || ''}
                  instantData={instantHelpData.data}
                  loading={instantHelpData.loading}
                />
              )}

              {instantHelpData.data.length === 0 && !instantHelpData.loading && !loading && (
                <div className={styles.noDataContainer}>
                  <h3>No data found</h3>
                </div>
              )}
            </Modal>
          </div>
        )}
      </AuthenticationLayout>
    </div>
  );
};

export default IntroductionPage;
