import { MotionButton } from '@shared/atoms';
import React from 'react';
import styles from './index.module.scss';

type buttonVariants = 'primarySkyBlue' | 'primaryDarkSkyBlue' | 'outlinedPrimarySkyBlue';

interface Props {
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  whileHover?: { scale?: number; opacity?: number };
  whileTap?: { scale?: number; opacity?: number };
  disabled?: boolean;
  loading?: boolean;
  variant?: buttonVariants;
  size?: 'small' | 'medium' | 'large';
  fullWidthButton?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

export const SystemButton: React.FC<Props> = ({
  onClick,
  children,
  className = '',
  whileHover = {},
  whileTap = {},
  disabled = false,
  loading = false,
  variant = 'primarySkyBlue',
  size = 'medium',
  fullWidthButton = false,
  type,
  ...rest
}) => (
  <MotionButton
    whileHover={!disabled && !loading ? whileHover : {}}
    whileTap={!disabled && !loading ? whileTap : {}}
    onClick={!loading ? onClick : undefined}
    className={`${styles.button} ${styles[variant]} ${styles[size]} ${
      fullWidthButton ? styles.fullWidth : styles.fitContent
    } ${className} ${disabled || loading ? styles.disabled : ''}`}
    disabled={disabled || loading}
    transition={{ type: 'spring', stiffness: 300, damping: 20 }}
    type={type}
    {...rest}
  >
    {loading ? <span className={styles.loader} /> : children}
  </MotionButton>
);

SystemButton.defaultProps = {
  className: '',
  whileHover: {},
  whileTap: {},
  disabled: false,
  loading: false,
  variant: 'primarySkyBlue',
  size: 'medium',
  onClick: undefined,
  fullWidthButton: false,
  type: 'button',
};
