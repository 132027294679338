import React from 'react';
import styles from './index.module.scss';

interface ProgressBarProps {
  progress: number;
  className?: string;
}
export const LineProgressBar: React.FC<ProgressBarProps> = ({ progress, className }) => (
  <div className={className}>
    <div className={styles.progressBar} style={{ width: `${progress}%` }} />
  </div>
);

LineProgressBar.defaultProps = {
  className: styles.progressBarContainer,
};
