import { ApiResponse } from '@interfaces/index';
import { apiUrlConfig } from './configs/apiUrlConfig';
import createApiClient from './configs/httpHandler';

export const learningContentApiHandler = {
  feedLearningsToDB: async (): Promise<ApiResponse<FedLearningContentResponseInterface>> =>
    createApiClient(apiUrlConfig.EXPRESS_BACKEND_V2).get(`/feed-learning-content`),

  searchLearningContent: async (
    searchQuery: string,
  ): Promise<ApiResponse<SearchedLearningContentInterface[]>> =>
    createApiClient(apiUrlConfig.EXPRESS_BACKEND_V2).get(`/learning-content-search/${searchQuery}`),

  logLearningSearchQuery: async (
    payload: Partial<LearningSearchLogPayload>,
  ): Promise<ApiResponse<LearningSearchLogResponse>> =>
    createApiClient(apiUrlConfig.EXPRESS_BACKEND_V2).post('/learning-content-search-logs', payload),
};

//RESPONSE
interface InsertedPost {
  id: number;
  title: string;
  image_url: string;
  client_custom_content: boolean;
  show_in_employee_portal: boolean;
  content_created_at: string; // ISO date string
  content_modified_at: string; // ISO date string
  description: string;
  content_type_id: number;
}

export interface FedLearningContentResponseInterface {
  count: number;
  insertedPosts: InsertedPost[];
}

export interface SearchedLearningContentInterface {
  id: number;
  title: string;
  imageUrl: string;
  type: string;
}

// LOG PAYLOAD
export interface LearningSearchLogPayload {
  searchQuery: string;
  contentId: number | null;
  tagId: number | null;
}

// LOG RESPONSE
export interface LearningSearchLogResponse {
  id: number;
  userId: number;
  logTypeId: number | null;
  ipAddress: string | null;
  os: string | null;
  isNative: boolean;
  createdAt: Date;
  updatedAt: Date;
}
