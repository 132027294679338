import { getCompanySignUpFields } from '@api/auth/company';
import { getSeniority } from '@api/seniority';
import { UpdateUserStatusPayload } from '@api/userStatusApiHandler';
import { completeRegisterUser, getUserById } from '@api/users';
import FormButtonNew from '@components/FormButtonNew/FormButtonNew';
import LabelInput from '@components/Molecules/LabelInput';
import LabelSelect from '@components/Molecules/LabelSelect';
import YearMonthSelect from '@components/Molecules/YearMonthSelect';
import { isEmptyValidation, isNullValidation, noValidation } from '@helpers/validation.helper';
import {
  useGetJobLocations,
  useGetJobTeams,
  useGetJobTitles,
  useGetJobTypes,
} from '@hooks/api/useAdminUsers';
import { useAppDispatch } from '@hooks/useAppDispatch';
import useInput, { getInputValue, getSelectValue } from '@hooks/useInput';
import useIsFormValid from '@hooks/useIsFormValid';
import { UserDetails } from '@interfaces/index';
import { Skeleton } from '@mui/material';
import { SelectableOptionType, SeniorityType } from 'custom';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { updateUserStatusData } from 'store/Actions/UserStatusAction';
import styles from './index.module.scss';

const CompleteRegistrationForm: React.FunctionComponent<CompleteRegistrationFormProps> = (
  props,
) => {
  const { user } = props;
  const [fields, setFields] = useState<Array<{ field_id: string; field_name: string }>>([]);
  const [teams, setTeams] = useState<Array<SelectableOptionType>>([]);
  const [locations, setLocations] = useState<Array<SelectableOptionType>>([]);
  const [jobTitles, setJobTitles] = useState<Array<SelectableOptionType>>([]);
  const [jobTypes, setJobTypes] = useState<Array<SelectableOptionType>>([]);
  const [seniorityList, setSeniorityList] = useState<Array<SelectableOptionType>>([]);
  const startWorkingYears = 18;
  const endWorkingYears = 80;
  const endYear = new Date().getFullYear() - startWorkingYears;
  const startYear = endYear - endWorkingYears;
  const history = useHistory();
  const dispatch = useAppDispatch();
  let isFormValid = false;
  const [dataFieldsLoading, setDataFields] = useState(true);
  const [userDetails, setUserDetails] = React.useState<UserDetails | null>(null);

  const enum FieldList {
    UNIQUE_ID = 'uniqueId',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    JOB_LOCATION = 'jobLocation',
    TEAM = 'team',
    JOB_TITLE = 'jobTitle',
    JOB_TYPE = 'jobType',
    SENIORITY = 'seniority',
    REPORTING = 'reporting',
    GENDER = 'gender',
    ETHNICITY = 'ethnicity',
    BIRTH_YEAR_MONTH = 'birthYearMonth',
  }

  useEffect(() => {
    getFields();
  }, [history]);

  useEffect(() => {
    if (user && user?.id) {
      getUser();
    }
  }, [user]);

  const getUser = async () => {
    const userDt = await getUserById(user.id);
    setUserDetails(userDt);
  };

  useEffect(() => {
    if (user && userDetails) {
      firstNameChangeHandler({ target: { value: userDetails.forename || '' } });
      lastNameChangeHandler({ target: { value: userDetails.surname || '' } });
      //  **************** For now commented, but in the future we can enable below fields autofill as well ****************
      // jobLocationChangeHandler(userDetails.location || null);
      // teamChangeHandler(userDetails.team || null);
      // jobTitleChangeHandler(userDetails.jobTitle || null);
      // jobTypeChangeHandler(userDetails.jobType || null);
      // seniorityChangeHandler(userDetails.seniority || null);
      // genderChangeHandler(userDetails.sex || null);
    }
  }, [user, userDetails]);

  const {
    data: titlesResponses,
    error: titlesResponsesError,
    isLoading: isTitlesResponsesLoading,
    refetch: refetchTitles,
  } = useGetJobTitles(true);

  const {
    data: typesResponses,
    error: typesResponsesError,
    isLoading: isTypesResponsesLoading,
    refetch: refetchTypes,
  } = useGetJobTypes(true);

  const {
    data: locationsResponses,
    error: locationsResponsesError,
    isLoading: isLocationsResponsesLoading,
    refetch: refetchLocations,
  } = useGetJobLocations(true);

  const {
    data: teamsResponses,
    error: teamsResponsesError,
    isLoading: isTeamsResponsesLoading,
    refetch: refetchTeams,
  } = useGetJobTeams(true);

  useEffect(() => {
    setIsLoading((prev) => ({ ...prev, teams: isTeamsResponsesLoading }));
    setIsLoading((prev) => ({ ...prev, locations: isLocationsResponsesLoading }));
    setIsLoading((prev) => ({ ...prev, types: isTypesResponsesLoading }));
    setIsLoading((prev) => ({ ...prev, titles: isTitlesResponsesLoading }));

    if (
      titlesResponsesError ||
      typesResponsesError ||
      locationsResponsesError ||
      teamsResponsesError
    ) {
      setIsLoading({
        locations: false,
        teams: false,
        jobTitles: false,
        jobTypes: false,
        seniority: false,
      });
    }
  }, [
    isTitlesResponsesLoading,
    isTypesResponsesLoading,
    isLocationsResponsesLoading,
    isTeamsResponsesLoading,
    titlesResponsesError,
    typesResponsesError,
    locationsResponsesError,
    teamsResponsesError,
  ]);

  const getFields = async () => {
    try {
      const fieldsList = await getCompanySignUpFields();
      if (fieldsList.length === 0) {
        history.push('/introduction');
      }

      setFields(fieldsList);
    } catch {
      setFields([]);
    } finally {
      setDataFields(false);
    }
  };

  const genderOptions: SelectableOptionType[] = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Non-binary', value: 'non_binary' },
    { label: 'Prefer not to answer', value: 'prefer_not_to_answer' },
  ];

  const ethnicityOptions: SelectableOptionType[] = [
    { label: 'Asian', value: 'asian' },
    { label: 'Black', value: 'black' },
    { label: 'White/Caucasian', value: 'white/caucasian' },
    { label: 'Hispanic/Latino', value: 'hispanic/latino' },
    { label: 'Middle Eastern/North African', value: 'middle_eastern/north_african' },
    { label: 'Mixed/Multiple ethnicities', value: 'mixed/multiple_ethnicities' },
    { label: 'Other', value: 'other' },
    { label: 'Prefer not to answer', value: 'prefer_not_to_answer' },
  ];

  const reportingOptions: SelectableOptionType[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const [isLoading, setIsLoading] = useState<{
    locations: boolean;
    teams: boolean;
    jobTitles: boolean;
    jobTypes: boolean;
    seniority: boolean;
  }>({
    locations: false,
    teams: false,
    jobTitles: false,
    jobTypes: false,
    seniority: false,
  });

  const getTeamData = async () => {
    if (teamsResponses?.success) {
      const transformedData = teamsResponses.responseObject.map((dt) => ({
        label: dt.name,
        value: dt.lookUpId,
      }));
      setTeams(transformedData);
    } else {
      setTeams([]);
    }
  };

  const getLocationData = async () => {
    if (locationsResponses?.success) {
      const transformedData = locationsResponses.responseObject.map((dt) => ({
        label: dt.name,
        value: dt.lookUpId,
      }));
      setLocations(transformedData);
    } else {
      setLocations([]);
    }
  };

  const getJobTypeData = async () => {
    if (typesResponses?.success) {
      const transformedData = typesResponses.responseObject.map((dt) => ({
        label: dt.name,
        value: dt.lookUpId,
      }));
      setJobTypes(transformedData);
    } else {
      setJobTypes([]);
    }
  };

  const getJobTitleData = async () => {
    if (titlesResponses?.success) {
      const transformedData = titlesResponses.responseObject.map((dt) => ({
        label: dt.name,
        value: dt.lookUpId,
      }));
      setJobTitles(transformedData);
    } else {
      setJobTitles([]);
    }
  };

  const getSeniorityData = async () => {
    const seniorityResponse = await getSeniority();
    const seniorityOptions = seniorityResponse
      ? seniorityResponse.map((seniorityType: SeniorityType) => ({
          label: seniorityType.name,
          value: seniorityType.id,
        }))
      : [];
    setSeniorityList(seniorityOptions);
  };

  useEffect(() => {
    if (fields.length > 0) {
      fields.forEach((_field) => {
        switch (_field.field_id) {
          case FieldList.JOB_LOCATION:
            getLocationData();
            break;

          case FieldList.TEAM:
            getTeamData();
            break;

          case FieldList.JOB_TITLE:
            getJobTitleData();
            break;

          case FieldList.JOB_TYPE:
            getJobTypeData();
            break;

          case FieldList.SENIORITY:
            getSeniorityData();
            break;

          default:
            break;
        }
      });
    }
  }, [
    fields,
    FieldList.JOB_LOCATION,
    FieldList.JOB_TITLE,
    FieldList.JOB_TYPE,
    FieldList.SENIORITY,
    FieldList.TEAM,
    titlesResponses,
    typesResponses,
    locationsResponses,
    teamsResponses,
  ]);

  const {
    value: uniqueId,
    isValid: uniqueIdIsValid,
    hasError: uniqueIdHasError,
    valueChangeHandler: uniqueIdChangeHandler,
    inputBlurHandler: uniqueIdBlurHandler,
    reset: uniqueIdNameReset,
  } = useInput(isEmptyValidation, getInputValue, '');

  const {
    value: firstName,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: firstNameReset,
  } = useInput(isEmptyValidation, getInputValue, '');

  const {
    value: lastName,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: lastNameReset,
  } = useInput(isEmptyValidation, getInputValue, '');

  const {
    value: jobLocation,
    isValid: jobLocationIsValid,
    hasError: jobLocationHasError,
    valueChangeHandler: jobLocationChangeHandler,
    inputBlurHandler: jobLocationBlurHandler,
    reset: jobLocationReset,
  } = useInput(isNullValidation, getSelectValue, null);

  const {
    value: team,
    isValid: teamIsValid,
    hasError: teamHasError,
    valueChangeHandler: teamChangeHandler,
    inputBlurHandler: teamBlurHandler,
    reset: teamReset,
  } = useInput(isNullValidation, getSelectValue, null);

  const {
    value: jobTitle,
    isValid: jobTitleIsValid,
    hasError: jobTitleHasError,
    valueChangeHandler: jobTitleChangeHandler,
    inputBlurHandler: jobTitleBlurHandler,
    reset: jobTitleReset,
  } = useInput(isNullValidation, getSelectValue, null);

  const {
    value: jobType,
    isValid: jobTypeIsValid,
    hasError: jobTypeHasError,
    valueChangeHandler: jobTypeChangeHandler,
    inputBlurHandler: jobTypeBlurHandler,
    reset: jobTypeReset,
  } = useInput(isNullValidation, getSelectValue, null);

  const {
    value: seniority,
    isValid: seniorityIsValid,
    hasError: seniorityHasError,
    valueChangeHandler: seniorityChangeHandler,
    inputBlurHandler: seniorityBlurHandler,
    reset: seniorityReset,
  } = useInput(isNullValidation, getSelectValue, null);

  const {
    value: reporting,
    isValid: reportingIsValid,
    hasError: reportingHasError,
    valueChangeHandler: reportingChangeHandler,
    inputBlurHandler: reportingBlurHandler,
    reset: reportingReset,
  } = useInput(isNullValidation, getSelectValue, reportingOptions[1]);

  const {
    value: gender,
    isValid: genderIsValid,
    hasError: genderHasError,
    valueChangeHandler: genderChangeHandler,
    inputBlurHandler: genderBlurHandler,
    reset: genderReset,
  } = useInput(isNullValidation, getSelectValue, null);

  const {
    value: ethnicity,
    isValid: ethnicityIsValid,
    hasError: ethnicityHasError,
    valueChangeHandler: ethnicityChangeHandler,
    inputBlurHandler: ethnicityBlurHandler,
    reset: ethnicityReset,
  } = useInput(isNullValidation, getSelectValue, null);

  const {
    value: month,
    isValid: monthIsValid,
    hasError: monthHasError,
    valueChangeHandler: monthChangeHandler,
    inputBlurHandler: monthBlurHandler,
    reset: monthReset,
  } = useInput(noValidation, getSelectValue, null);

  const {
    value: year,
    isValid: yearIsValid,
    hasError: yearHasError,
    valueChangeHandler: yearChangeHandler,
    inputBlurHandler: yearBlurHandler,
    reset: yearReset,
  } = useInput(noValidation, getSelectValue, null);

  let validatingFieldList: boolean[] = [];
  if (fields.length > 0) {
    validatingFieldList = fields.map((_field) => {
      switch (_field.field_id) {
        case FieldList.UNIQUE_ID:
          return uniqueIdIsValid;
          break;

        case FieldList.FIRST_NAME:
          return firstNameIsValid;
          break;

        case FieldList.LAST_NAME:
          return lastNameIsValid;
          break;

        case FieldList.JOB_LOCATION:
          return jobLocationIsValid;
          break;

        case FieldList.TEAM:
          return teamIsValid;
          break;

        case FieldList.JOB_TITLE:
          return jobTitleIsValid;
          break;

        case FieldList.JOB_TYPE:
          return jobTypeIsValid;
          break;

        case FieldList.SENIORITY:
          return seniorityIsValid;
          break;

        case FieldList.REPORTING:
          return reportingIsValid;
          break;

        case FieldList.GENDER:
          return genderIsValid;
          break;

        case FieldList.ETHNICITY:
          return ethnicityIsValid;
          break;

        default:
          return true;
          break;
      }
    });
  }

  isFormValid = useIsFormValid(...validatingFieldList);

  const handleCreateUser = async (e: any) => {
    e.preventDefault();
    let response: any;

    try {
      if (isFormValid) {
        const payload = {
          uniqueId,
          firstName,
          lastName,
          jobLocation: jobLocation ? jobLocation.value : null,
          team: team ? team.value : null,
          jobTitle: jobTitle ? jobTitle.value : null,
          jobType: jobType ? jobType.value : null,
          seniority: seniority ? seniority.value : null,
          reporting: reporting ? reporting.value : null,
          gender: gender ? gender.value : null,
          ethnicity: ethnicity ? ethnicity.value : null,
          birthMonth: month ? month.value : null,
          birthYear: year ? year.value : null,
        };
        response = await completeRegisterUser(user.id, payload);
      }

      if (response.data) {
        const data: UpdateUserStatusPayload = {
          isRegistered: true,
          isJoined: null,
          isEligibleForSpeakUpAlerts: null,
          isOnboardCompleted: null,
          isFirstShortStarCompleted: null,
        };
        dispatch(updateUserStatusData(data));
        await toast.success(response.data || 'Updated data successfully', {
          autoClose: 250,
          onClose: async () => {
            await history.push('/introduction');
          },
        });
      } else {
        toast.error(response.message || 'Error completing the registration please try again');
      }
    } catch (err: any) {
      toast.error(err.message || 'Error completing the registration please try again');
    }
  };

  let uiFields;
  if (fields.length > 0) {
    uiFields = fields.map((_field) => {
      switch (_field.field_id) {
        case FieldList.UNIQUE_ID:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelInput
                id="uniqueId"
                label={(_field.field_name && `${_field.field_name} *`) || 'User Id *'}
                type="text"
                name="uniqueId"
                placeholder={`Enter your ${
                  (_field.field_name && _field.field_name.toLowerCase()) || 'user id'
                } provided by your organisation`}
                onChange={uniqueIdChangeHandler}
                onBlur={uniqueIdBlurHandler}
                value={uniqueId || ''}
                isError={uniqueIdHasError}
              />
              {uniqueIdHasError && (
                <p className={styles.hsError}>
                  {`Please enter your ${
                    (_field.field_name && _field.field_name.toLowerCase()) || 'user id'
                  } provided by your organisation. If you are unsure about
                  your ${
                    (_field.field_name && _field.field_name.toLowerCase()) || 'user id'
                  }, please contact your administrator for assistance.`}
                </p>
              )}
            </div>
          );
          break;

        case FieldList.FIRST_NAME:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelInput
                id="firstName"
                label={(_field.field_name && `${_field.field_name} *`) || 'First Name *'}
                type="text"
                name="firstName"
                placeholder={`Enter your ${
                  (_field.field_name && _field.field_name.toLowerCase()) || 'first name'
                }`}
                onChange={firstNameChangeHandler}
                onBlur={firstNameBlurHandler}
                value={firstName || ''}
                isError={firstNameHasError}
              />
              {firstNameHasError && (
                <p className={styles.hsError}>
                  {`Please enter your ${
                    (_field.field_name && _field.field_name.toLowerCase()) || 'first name'
                  }`}
                </p>
              )}
            </div>
          );
          break;

        case FieldList.LAST_NAME:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelInput
                id="lastName"
                label={(_field.field_name && `${_field.field_name} *`) || 'Last Name *'}
                type="text"
                name="lastName"
                placeholder={`Enter your ${
                  (_field.field_name && _field.field_name.toLowerCase()) || 'last name'
                }`}
                onChange={lastNameChangeHandler}
                onBlur={lastNameBlurHandler}
                value={lastName || ''}
                isError={lastNameHasError}
              />
              {lastNameHasError && (
                <p className={styles.hsError}>
                  {`Please enter your ${
                    (_field.field_name && _field.field_name.toLowerCase()) || 'last name'
                  }`}
                </p>
              )}
            </div>
          );
          break;

        case FieldList.JOB_LOCATION:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelSelect
                id="jobLocation"
                name="jobLocation"
                label={(_field.field_name && `${_field.field_name} *`) || 'Job location *'}
                options={locations}
                value={jobLocation || null}
                placeholder={
                  isLoading.locations
                    ? 'Loading...'
                    : `Select your ${
                        (_field.field_name && _field.field_name.toLowerCase()) || 'job location'
                      }`
                }
                onChange={jobLocationChangeHandler}
                onBlur={jobLocationBlurHandler}
                isError={jobLocationHasError}
                isDisabled={isLoading.locations}
              />
              {jobLocationHasError && (
                <p className={styles.hsError}>
                  {`Please select your ${
                    (_field.field_name && _field.field_name.toLowerCase()) || 'job location'
                  }`}
                </p>
              )}
            </div>
          );
          break;

        case FieldList.JOB_TYPE:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelSelect
                id="jobType"
                name="jobType"
                label={(_field.field_name && `${_field.field_name} *`) || 'Type *'}
                options={jobTypes}
                placeholder={`Select ${
                  (_field.field_name && _field.field_name.toLowerCase()) || 'type'
                }`}
                onChange={jobTypeChangeHandler}
                onBlur={jobTypeBlurHandler}
                value={jobType || null}
                isError={jobTypeHasError}
              />
              {jobTypeHasError && (
                <p className={styles.hsError}>{`Please select ${
                  (_field.field_name && _field.field_name.toLowerCase()) || 'type'
                }`}</p>
              )}
            </div>
          );
          break;

        case FieldList.TEAM:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelSelect
                id="team"
                name="team"
                label={(_field.field_name && `${_field.field_name} *`) || 'Team *'}
                options={teams}
                value={team || null}
                placeholder={
                  isLoading.teams
                    ? 'Loading...'
                    : `Select ${(_field.field_name && _field.field_name.toLowerCase()) || 'team'}`
                }
                onChange={teamChangeHandler}
                onBlur={teamBlurHandler}
                isError={teamHasError}
                isDisabled={isLoading.teams}
              />
              {teamHasError && (
                <p className={styles.hsError}>{`Please select a ${
                  (_field.field_name && _field.field_name.toLowerCase()) || 'team'
                }`}</p>
              )}
            </div>
          );
          break;

        case FieldList.JOB_TITLE:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelSelect
                id="jobTitle"
                name="jobTitle"
                label={(_field.field_name && `${_field.field_name} *`) || 'Job title *'}
                options={jobTitles}
                value={jobTitle || null}
                placeholder={`Select your ${
                  (_field.field_name && _field.field_name.toLowerCase()) || 'job title'
                }`}
                onChange={jobTitleChangeHandler}
                onBlur={jobTitleBlurHandler}
                isError={jobTitleHasError}
              />
              {jobTitleHasError && (
                <p className={styles.hsError}>
                  {`Please select your ${
                    (_field.field_name && _field.field_name.toLowerCase()) || 'job title'
                  }`}
                </p>
              )}
            </div>
          );
          break;

        case FieldList.SENIORITY:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelSelect
                id="seniority"
                name="seniority"
                label={(_field.field_name && `${_field.field_name} *`) || 'Seniority *'}
                options={seniorityList}
                placeholder={`Select your ${
                  (_field.field_name && _field.field_name.toLowerCase()) || 'seniority'
                }`}
                value={seniority || null}
                onChange={seniorityChangeHandler}
                onBlur={seniorityBlurHandler}
                isError={seniorityHasError}
              />
              {seniorityHasError && (
                <p className={styles.hsError}>
                  {`Please select your ${
                    (_field.field_name && _field.field_name.toLowerCase()) || 'seniority'
                  }`}
                </p>
              )}
            </div>
          );
          break;

        case FieldList.REPORTING:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelSelect
                id="reporting"
                name="reporting"
                label={
                  (_field.field_name && `${_field.field_name} *`) || 'Anyone reporting to you? *'
                }
                options={reportingOptions}
                value={reporting || reportingOptions[1]}
                onChange={reportingChangeHandler}
                onBlur={reportingBlurHandler}
                isError={reportingHasError}
                isClearable={false}
              />
            </div>
          );
          break;

        case FieldList.GENDER:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelSelect
                id="gender"
                name="gender"
                label={(_field.field_name && `${_field.field_name} *`) || 'Gender *'}
                options={genderOptions}
                value={gender || null}
                placeholder={`Select your ${
                  (_field.field_name && _field.field_name.toLowerCase()) || 'gender'
                }`}
                onChange={genderChangeHandler}
                onBlur={genderBlurHandler}
                isError={genderHasError}
              />
              {genderHasError && (
                <p className={styles.hsError}>
                  {`Please select your ${
                    (_field.field_name && _field.field_name.toLowerCase()) || 'gender'
                  }`}
                </p>
              )}
            </div>
          );
          break;

        case FieldList.ETHNICITY:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelSelect
                id="ethnicity"
                name="ethnicity"
                label={(_field.field_name && `${_field.field_name} *`) || 'Ethnicity *'}
                options={ethnicityOptions}
                value={ethnicity || null}
                placeholder={`Select your ${
                  (_field.field_name && _field.field_name.toLowerCase()) || 'ethnicity'
                }`}
                onChange={ethnicityChangeHandler}
                onBlur={ethnicityBlurHandler}
                isError={ethnicityHasError}
              />
              {ethnicityHasError && (
                <p className={styles.hsError}>
                  {`Please select your ${
                    (_field.field_name && _field.field_name.toLowerCase()) || 'ethnicity'
                  }`}
                </p>
              )}
            </div>
          );
          break;

        case FieldList.BIRTH_YEAR_MONTH:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <YearMonthSelect
                id="birthYearMonth"
                name="birthYearMonth"
                label={_field.field_name || 'Month and year of birth'}
                startYear={startYear}
                endYear={endYear}
                onMonthChange={monthChangeHandler}
                onYearChange={yearChangeHandler}
                onMonthBlur={monthBlurHandler}
                onYearBlur={yearBlurHandler}
              />
            </div>
          );
          break;

        default:
          return <></>;
          break;
      }
    });
  }

  return (
    <>
      {dataFieldsLoading ? (
        <div className={styles.loadingContainer}>
          <Skeleton variant="rounded" height={46} width="100%" className={styles.loadingItem} />
          <Skeleton variant="rounded" height={46} width="100%" className={styles.loadingItem} />
          <Skeleton variant="rounded" height={46} width="100%" className={styles.loadingItem} />
          <Skeleton variant="rounded" height={46} width="100%" className={styles.loadingItem} />
          <Skeleton variant="rounded" height={46} width="100%" className={styles.loadingItem} />
          <Skeleton variant="rounded" height={46} width="100%" className={styles.loadingItem} />
          <Skeleton variant="rounded" height={46} width="100%" className={styles.loadingItem} />
          <Skeleton variant="rounded" height={46} width="100%" className={styles.loadingItem} />
        </div>
      ) : (
        <form onSubmit={handleCreateUser}>
          {uiFields}
          <div className={styles.buttonHolder}>
            <FormButtonNew
              onClick={handleCreateUser}
              disabled={!isFormValid}
              color="primary"
              type="submit"
            >
              Enter Hapstar
            </FormButtonNew>
          </div>
        </form>
      )}
    </>
  );
};

CompleteRegistrationForm.displayName = 'CompleteRegistrationForm';

interface CompleteRegistrationFormProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  user: any;
}

export default CompleteRegistrationForm;
