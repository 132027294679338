import { motion } from 'framer-motion';
import React from 'react';
import styles from './index.module.scss';

interface MotionButtonProps {
  onClick?: () => void | undefined;
  children: React.ReactNode;
  className?: string;
  whileHover?: { scale?: number; opacity?: number };
  whileTap?: { scale?: number; opacity?: number };
  disabled?: boolean;
  transition?: { type?: string; stiffness?: number; damping?: number };
  type?: 'button' | 'submit' | 'reset';
}
export const MotionButton: React.FC<MotionButtonProps> = ({
  onClick,
  children,
  className,
  whileHover,
  whileTap,
  disabled,
  type,
  ...rest
}) => (
  <motion.button
    whileHover={whileHover}
    whileTap={whileTap}
    onClick={onClick}
    className={className}
    disabled={disabled}
    type={type}
    {...rest}
  >
    {children}
  </motion.button>
);

MotionButton.defaultProps = {
  onClick: undefined,
  className: styles.button,
  whileHover: { scale: 1.05 },
  whileTap: { scale: 0.95 },
  disabled: false,
  transition: { type: 'spring', stiffness: 300, damping: 20 },
  type: 'button',
};
