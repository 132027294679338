import { getCompanyDetailsById, getCompanySignUpFields } from '@api/auth/company';
import FormInputSelect from '@components/FormInputSelect';
import FormInputText from '@components/FormInputText';
import ManageAdminFieldsModal from '@components/Organisms/ManageAdminFieldsModal';
import SettingsIcon from '@mui/icons-material/Settings';
import React, { useEffect, useState } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import {
  CompanyDetails,
  CompanyTypes,
  SelectableJobOptionType,
  SelectableOptionType,
  UserRoles,
  UserRolesList,
} from '../../custom.d';
import styles from './index.module.scss';

const FormFieldsUser: React.FunctionComponent<FormFieldsUserProps> = (props) => {
  const {
    errors,
    register,
    control,
    teams,
    locations,
    jobTitles,
    jobTypes,
    onAddTeam,
    loading,
    loadingTeams,
    loadingLocations,
    loadingJobTitles,
    loadingJobTypes,
    onAddLocation,
    onAddJobTitle,
    onAddJobType,
    isEdit,
    getValues,
    setValue,
    selectedUserRole,
    handleRefetchJobOptionItems,
  } = props;
  const company = JSON.parse(localStorage.getItem('company') || '{}');
  const [compDetails, setCompDetails] = useState<CompanyDetails | null>(null);
  const [manageModalActions, setManageModalActions] = useState<{
    open: boolean;
    type: 'jobTypes' | 'jobTitles' | 'teams' | 'locations' | null;
    name: string | null;
  }>({ open: false, type: null, name: null });

  const handleManageModalActions = (
    open: boolean,
    type: 'teams' | 'locations' | 'jobTitles' | 'jobTypes' | null,
    name: string | null,
  ) => {
    setManageModalActions({ open, type, name });
  };

  const [fields, setFields] = useState<Array<{ field_id: string; field_name: string }>>([]);

  const [jobTitleFieldName, setJobTitleFieldName] = useState<string | null>(null);
  const [jobLocationFieldName, setJobLocationFieldName] = useState<string | null>(null);
  const [jobTypeFieldName, setJobTypeFieldName] = useState<string | null>(null);
  const [firstNameFieldName, setFirstNameFieldName] = useState<string | null>(null);
  const [lastNameFieldName, setLastNameFieldName] = useState<string | null>(null);
  const [teamFieldName, setTeamFieldName] = useState<string | null>(null);
  const [seniorityFieldName, setSeniorityFieldName] = useState<string | null>(null);
  const [genderFieldName, setGenderFieldName] = useState<string | null>(null);
  const [birthYearMonthFieldName, setBirthYearMonthFieldName] = useState<string | null>(null);
  const [ethnicityFieldName, setEthnicityFieldName] = useState<string | null>(null);
  const [payLevelFieldName, setPayLevelFieldName] = useState<string | null>(null);
  const [uniqueIdFieldName, setUniqueIdFieldName] = useState<string | null>(null);

  useEffect(() => {
    if (company) {
      getCompanyDetails();
      getFields();
    }
  }, []);

  const getCompanyDetails = async () => {
    const companyDetails = await getCompanyDetailsById(company.id);
    setCompDetails(companyDetails);
  };

  const getFields = async () => {
    try {
      const fieldsList = await getCompanySignUpFields();
      setFields(fieldsList);

      const getValidFieldName = (fieldId: string) => {
        const field = fieldsList.find((dt: { field_id: string }) => dt.field_id === fieldId);

        return field?.field_name && field.field_name !== 'null' ? field.field_name : null;
      };

      setJobTitleFieldName(getValidFieldName('jobTitle'));
      setJobLocationFieldName(getValidFieldName('jobLocation'));
      setJobTypeFieldName(getValidFieldName('jobType'));
      setFirstNameFieldName(getValidFieldName('firstName'));
      setLastNameFieldName(getValidFieldName('lastName'));
      setTeamFieldName(getValidFieldName('team'));
      setSeniorityFieldName(getValidFieldName('seniority'));
      setGenderFieldName(getValidFieldName('gender'));
      setBirthYearMonthFieldName(getValidFieldName('birthYearMonth'));
      setEthnicityFieldName(getValidFieldName('ethnicity'));
      setPayLevelFieldName(getValidFieldName('payLevel'));
      setUniqueIdFieldName(getValidFieldName('uniqueId'));
    } catch {
      setFields([]);
    }
  };

  return (
    <div className={styles.flexContainer}>
      <ManageAdminFieldsModal
        actions={manageModalActions}
        handleActions={handleManageModalActions}
        teams={teams}
        locations={locations}
        jobTitles={jobTitles}
        jobTypes={jobTypes}
        handleRefetchJobOptionItems={handleRefetchJobOptionItems}
      />
      <div>
        <FormInputText
          autoFocus
          placeholder={firstNameFieldName || 'Forename'}
          title={firstNameFieldName || 'Forename'}
          disabled={loading}
          isError={Boolean(errors.forename)}
          {...register('forename')}
        />
      </div>

      <div>
        <FormInputText
          placeholder={lastNameFieldName || 'Surname'}
          title={lastNameFieldName || 'Surname'}
          disabled={loading}
          isError={Boolean(errors.surname)}
          {...register('surname')}
        />
      </div>

      {!isEdit && (
        <div>
          <FormInputText
            placeholder="Email"
            title="Email"
            type="email"
            disabled={loading}
            isError={Boolean(errors.email)}
            {...register('email')}
          />
        </div>
      )}

      <div>
        <FormInputText
          placeholder={uniqueIdFieldName || 'Unique Id'}
          title={uniqueIdFieldName || 'Unique Id'}
          type="text"
          disabled={loading}
          isError={Boolean(errors.uniqueId)}
          {...register('uniqueId')}
        />
      </div>

      <div className={styles.manageableItem}>
        <div className={styles.formContainer}>
          <div className={styles.formLabel}>{jobTypeFieldName || 'Job Type'}</div>
          <Controller
            control={control}
            name="jobType"
            render={({ field }) => (
              <CreatableSelect
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? '#3FB9FC' : '#ECE7DC',
                    borderWidth: 2,
                    borderRadius: 10,
                    minHeight: 55,
                  }),
                  multiValue: (st) => ({
                    ...st,
                    backgroundColor: '#3FB9FC',
                    color: '#FFF',
                  }),
                  multiValueLabel: (st) => ({
                    ...st,
                    color: '#FFF',
                  }),
                }}
                placeholder="Enter or select"
                allowCreateWhileLoading={false}
                isLoading={loadingJobTypes}
                isSearchable
                isClearable
                options={jobTypes}
                onCreateOption={onAddJobType}
                {...field}
              />
            )}
          />
        </div>

        <span
          className={styles.manage}
          onClick={() =>
            setManageModalActions({
              open: true,
              type: 'jobTypes',
              name: jobTypeFieldName || 'Job Types',
            })
          }
        >
          <SettingsIcon sx={{ fontSize: '16px' }} /> Manage
        </span>
      </div>

      <div className={styles.manageableItem}>
        <div className={styles.formContainer}>
          <div className={styles.formLabel}>{teamFieldName || 'Team'}</div>
          <Controller
            control={control}
            name="team"
            render={({ field }) => (
              <CreatableSelect
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? '#3FB9FC' : '#ECE7DC',
                    borderWidth: 2,
                    borderRadius: 10,
                    minHeight: 55,
                  }),
                  multiValue: (st) => ({
                    ...st,
                    backgroundColor: '#3FB9FC',
                    color: '#FFF',
                  }),
                  multiValueLabel: (st) => ({
                    ...st,
                    color: '#FFF',
                  }),
                }}
                placeholder="Enter or select"
                allowCreateWhileLoading={false}
                isLoading={loadingTeams}
                isSearchable
                isClearable
                options={teams}
                onCreateOption={onAddTeam}
                {...field}
              />
            )}
          />
        </div>
        <span
          className={styles.manage}
          onClick={() =>
            setManageModalActions({ open: true, type: 'teams', name: teamFieldName || 'Teams' })
          }
        >
          <SettingsIcon sx={{ fontSize: '16px' }} /> Manage
        </span>
      </div>

      <div className={styles.manageableItem}>
        <div className={styles.formContainer}>
          <div className={styles.formLabel}>{jobLocationFieldName || 'Location'}</div>
          <Controller
            control={control}
            name="location"
            render={({ field }) => (
              <CreatableSelect
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? '#3FB9FC' : '#ECE7DC',
                    borderWidth: 2,
                    borderRadius: 10,
                    minHeight: 55,
                  }),
                  multiValue: (st) => ({
                    ...st,
                    backgroundColor: '#3FB9FC',
                    color: '#FFF',
                  }),
                  multiValueLabel: (st) => ({
                    ...st,
                    color: '#FFF',
                  }),
                }}
                placeholder="Enter or select"
                allowCreateWhileLoading={false}
                isLoading={loadingLocations}
                isSearchable
                isClearable
                options={locations}
                onCreateOption={onAddLocation}
                {...field}
              />
            )}
          />
        </div>
        <span
          className={styles.manage}
          onClick={() =>
            setManageModalActions({
              open: true,
              type: 'locations',
              name: jobLocationFieldName || 'Locations',
            })
          }
        >
          <SettingsIcon sx={{ fontSize: '16px' }} /> Manage
        </span>
      </div>

      <div className={styles.manageableItem}>
        <div className={styles.formContainer}>
          <div className={styles.formLabel}>{jobTitleFieldName || 'Job Title'}</div>
          <Controller
            control={control}
            name="jobTitle"
            render={({ field }) => (
              <CreatableSelect
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? '#3FB9FC' : '#ECE7DC',
                    borderWidth: 2,
                    borderRadius: 10,
                    minHeight: 55,
                  }),
                  multiValue: (st) => ({
                    ...st,
                    backgroundColor: '#3FB9FC',
                    color: '#FFF',
                  }),
                  multiValueLabel: (st) => ({
                    ...st,
                    color: '#FFF',
                  }),
                }}
                placeholder="Enter or select"
                allowCreateWhileLoading={false}
                isLoading={loadingJobTitles}
                isSearchable
                isClearable
                options={jobTitles}
                onCreateOption={onAddJobTitle}
                {...field}
              />
            )}
          />
        </div>{' '}
        <span
          className={styles.manage}
          onClick={() =>
            setManageModalActions({
              open: true,
              type: 'jobTitles',
              name: jobTitleFieldName || 'Job Titles',
            })
          }
        >
          <SettingsIcon sx={{ fontSize: '16px' }} /> Manage
        </span>
      </div>

      <div>
        <FormInputSelect
          isError={Boolean(errors.sex)}
          title={genderFieldName || 'Gender'}
          variant="secondary"
          {...register('sex')}
        >
          <option value="non_specified">-</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="non_binary">Non-binary</option>
          <option value="prefer_not_to_answer">Prefer not to answer</option>
        </FormInputSelect>
      </div>

      <div>
        <FormInputSelect
          isError={Boolean(errors.ethnicity)}
          title={ethnicityFieldName || 'Ethnicity'}
          variant="secondary"
          {...register('ethnicity')}
        >
          <option value="asian">Asian</option>
          <option value="black">Black</option>
          <option value="white/caucasian">White/Caucasian</option>
          <option value="hispanic/latino">Hispanic/Latino</option>
          <option value="middle_eastern/north_african">Middle Eastern/North African</option>
          <option value="mixed/multiple_ethnicities">Mixed/Multiple ethnicities</option>
          <option value="other">Other</option>
          <option value="prefer_not_to_answer">Prefer not to answer</option>
        </FormInputSelect>
      </div>

      <div>
        <FormInputSelect
          isError={Boolean(errors.seniority)}
          title={seniorityFieldName || 'Seniority'}
          variant="secondary"
          {...register('seniority')}
        >
          <option value="non_specified">-</option>
          <option value="junior">Junior</option>
          <option value="mid_level">Mid-level</option>
          <option value="senior">Senior</option>
          <option value="c_level">C-level</option>
        </FormInputSelect>
      </div>

      <div>
        <FormInputText
          isError={Boolean(errors.payLevel)}
          disabled={loading}
          title={payLevelFieldName || 'Pay Level'}
          placeholder="£"
          type="number"
          {...register('payLevel')}
        />
      </div>

      <div>
        <div className={styles.formLabel}>People Lead</div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="peopleLeadSwitch"
            {...register('peopleLead')}
          />
        </div>
      </div>

      <div>
        <FormInputSelect
          isError={Boolean(errors.userRole)}
          title="User Role"
          variant="secondary"
          {...register('userRole')}
        >
          {UserRolesList.filter(
            (rl) => rl.value !== UserRoles.SUPER_ADMIN || rl.value === getValues('userRole'), // Keep "Super Admin" if it's the selected role
          ).map((role: { value: string; label: string }, index: number) => (
            <option
              key={index}
              value={role.value}
              disabled={
                role.value === UserRoles.SUPER_ADMIN || // Disable "Super Admin"
                (role.value === 'advisor' &&
                  (compDetails?.company_type?.toLowerCase() ?? '') !== CompanyTypes.EMPLOYABILITY)
              }
            >
              {role.label}
            </option>
          ))}
        </FormInputSelect>
      </div>

      {(getValues('userRole') === UserRoles.ADMIN ||
        getValues('userRole') === UserRoles.SUPER_ADMIN) && (
        <div>
          <div className={styles.formLabel}>Receive Speak Up Alerts</div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="speakupAlertEligibility"
              {...register('speakupAlertEligibility')}
            />
          </div>
        </div>
      )}

      <div>
        <FormInputText
          isError={Boolean(errors.dob)}
          disabled={loading}
          title={birthYearMonthFieldName || 'D.O.B'}
          type="date"
          {...register('dob')}
        />
      </div>

      <div>
        <FormInputText
          isError={Boolean(errors.joined)}
          disabled={loading}
          title="Joined"
          type="date"
          {...register('joined')}
        />
      </div>
    </div>
  );
};

interface FormFieldsUserProps {
  isEdit?: boolean;
  control: Control<any>;
  register: any;
  errors: FieldErrors;
  teams: Array<SelectableJobOptionType>;
  locations: Array<SelectableJobOptionType>;
  jobTitles: Array<SelectableJobOptionType>;
  jobTypes: Array<SelectableJobOptionType>;
  onAddTeam: (name: string) => void;
  onAddLocation: (name: string) => void;
  onAddJobTitle: (name: string) => void;
  onAddJobType: (name: string) => void;
  loadingTeams: boolean;
  loadingLocations: boolean;
  loadingJobTitles: boolean;
  loadingJobTypes: boolean;
  loading: boolean;
  getValues: any;
  setValue: any;
  selectedUserRole?: SelectableOptionType;
  handleRefetchJobOptionItems: (arg1: string) => void;
}

FormFieldsUser.defaultProps = {
  isEdit: false,
  selectedUserRole: UserRolesList[0],
};

export default FormFieldsUser;
