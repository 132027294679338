import { Box } from '@mui/material';
import { Reflection } from '@shared/organisms';
import { CoreLayout } from '@shared/templates';
import React from 'react';
import { useHistory } from 'react-router';
import styles from './styles.module.scss';

export const ReflectionPage: React.FC = () => {
  const history = useHistory();

  return (
    <CoreLayout title="Reflection Page" navigationType="default">
      <div className={styles.container}>
        <Box className={styles.globalRootCard} sx={{ maxWidth: '600px' }}>
          <Reflection
            handleStep={() => {
              history.push('/');
            }}
          />
        </Box>
      </div>
    </CoreLayout>
  );
};
