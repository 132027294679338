import {
  CompanyQuestionnaireDataInterface,
  CreateUserAssessment,
  QuestionnaireInterface,
  UserResponse,
  assessmentQuestionnaireApiHandler,
} from '@api/assessmentQuestionnaireApiHandler';
import { smoothScrollTo } from '@helpers/general.helpers';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { ApiResponse } from '@interfaces/index';
import { Box } from '@mui/material';
import { LoadingDots, MotionText, showToast } from '@shared/atoms';
import { SystemButton } from '@shared/molecules';
import { HelpAndSpeakUp } from '@shared/organisms/EWS/HelpAndSpeakUp/HelpAndSpeakUp';
import { useMutation } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { RootState } from 'store';
import {
  answerQuestion,
  nextQuestion,
  previousQuestion,
  resetQuestionnaire,
  setChosenQuestionnaire,
  setCreatedUserAssessment,
  setNavigationStep,
  setQuestionnaireQuestions,
} from 'store/Slices/QuestionnaireSliceV2';
import { AssessmentLoadingScreen } from '../AssessmentLoadingScreen/AssessmentLoadingScreen';
import { QuestionCard } from '../QuestionCard/QuestionCard';
import { QuestionnaireBrakeOff } from '../QuestionnaireBrakeOff/QuestionnaireBrakeOff';
import { Reflection } from '../Reflection/Reflection';
import { TalkToAdviserCategorySelection } from '../TalkToAdviserCategorySelection/TalkToAdviserCategorySelection';
import styles from './styles.module.scss';

const ContentWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.card}>{children}</div>
  </div>
);

interface SingleQuestionnaireProps {
  companyQuestionnaires: CompanyQuestionnaireDataInterface[] | [];
  questionsData: ApiResponse<QuestionnaireInterface> | undefined;
  questionnairesLoading?: boolean;
  questionnairesError?: Error | null;
  questionsLoading: boolean;
  questionsError?: Error | null;
  isOnboarding?: boolean;
  onComplete?: () => void;
}

const mapAnswersToUserResponses = (
  userAnswers: SubmitAssessmentPayload['answers'],
): UserResponse[] =>
  Object.values(userAnswers).map((answer) => ({
    questionId: answer.questionId,
    selectedOptionId: answer.answerId,
    score: answer.answerScore,
  }));

export const SingleQuestionnaire: React.FC<SingleQuestionnaireProps> = (props) => {
  const {
    companyQuestionnaires: questionnaires,
    questionsData,
    questionnairesLoading,
    questionnairesError,
    questionsLoading,
    questionsError,
    isOnboarding,
    onComplete,
  } = props;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const { isDesktop } = useWindowDimensions();
  const companyConfig = useAppSelector((state: RootState) => state.companyConfig.data);
  const { showSpeakUp, showInstantHelp } = companyConfig;
  const { currentQuestionIndex, questionnaire, answers, chosenQuestionnaire, navigationStep } =
    useAppSelector((state) => state.questionnaireV2 || {});
  const [isLoading, setIsLoading] = useState(false);
  const [questionnaireSubmitting, setQuestionnaireSubmitting] = useState(false);
  const [showEWS, setShowEWS] = useState<boolean>(false);
  const [showWellbeingHub, setShowWellbeingHub] = useState<boolean>(false);
  const queryParams = new URLSearchParams(location.search);
  const contextType = queryParams.get('contextType');

  useEffect(() => {
    if (contextType === 'reflection' && chosenQuestionnaire) {
      dispatch(setNavigationStep('reflection'));
    }
  }, [history, location, contextType, navigationStep, chosenQuestionnaire]);

  const useSubmitUserAssessment = () =>
    useMutation({
      mutationFn: ({ questionnaireId, answers: userAnswers }: SubmitAssessmentPayload) => {
        const payload: CreateUserAssessment = {
          questionnaireId,
          userResponses: mapAnswersToUserResponses(userAnswers),
        };

        return assessmentQuestionnaireApiHandler.createUserAssessment(payload);
      },
    });

  const submitAssessmentMutation = useSubmitUserAssessment();

  useEffect(() => {
    if (questionsData) {
      const { success, responseObject } = questionsData;
      if (success) {
        dispatch(setQuestionnaireQuestions(responseObject));
      } else {
        dispatch(setQuestionnaireQuestions(null));
      }
    } else {
      dispatch(setQuestionnaireQuestions(null));
    }

    setIsLoading(true);
    const timeoutId = setTimeout(() => {
      setIsLoading(questionsLoading);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [questionsData, chosenQuestionnaire?.questionnaireId]);

  if (isLoading) {
    setTimeout(() => {
      setIsLoading(questionsLoading);
    }, 2000);

    return (
      <ContentWrapper>
        <AssessmentLoadingScreen loading={isLoading} />
      </ContentWrapper>
    );
  }

  if (contextType === 'reflection' && !chosenQuestionnaire) {
    return (
      <div className={styles.container}>
        <Box className={styles.globalRootCard} sx={{ maxWidth: '600px' }}>
          <Reflection
            handleStep={() => {
              dispatch(resetQuestionnaire());
              queryParams.delete('contextType');

              history.push({ search: queryParams.toString() });
            }}
          />
        </Box>
      </div>
    );
  }

  if (
    (chosenQuestionnaire === null || !chosenQuestionnaire) &&
    !isOnboarding &&
    !showEWS &&
    contextType !== 'reflection'
  ) {
    return (
      <ContentWrapper>
        <div className={styles.wrapperQuestionnaire}>
          <MotionText
            as="h1"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, ease: 'easeOut' }}
            text="Choose a questionnaire to start your assessment"
          />

          {questionnairesLoading ? (
            <LoadingDots
              size={isDesktop ? 16 : 25}
              marginTop={isDesktop ? '50px' : '60%'}
              isHapstarLoading
              dotCount={isDesktop ? 5 : 3}
            />
          ) : (
            <div className={styles.list}>
              {questionnaires.map((dt, index) => (
                <motion.div
                  key={index}
                  className={styles.questionnaireCard}
                  onClick={() => {
                    dispatch(setChosenQuestionnaire(dt));
                    setIsLoading(true);
                  }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, ease: 'easeOut', delay: index * 0.1 }}
                >
                  <MotionText
                    as="p"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, ease: 'easeOut' }}
                    text={dt.questionnaireV2.name}
                    className={styles.text}
                  />
                </motion.div>
              ))}
            </div>
          )}

          {!questionnairesLoading && questionnaires?.length === 0 && (
            <MotionText
              as="p"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, ease: 'easeOut' }}
              text="No questionnaires found."
            />
          )}
        </div>
      </ContentWrapper>
    );
  }

  if (!questionnaire || questionnairesError || questionsError) {
    return (
      <ContentWrapper>
        <motion.div
          className={styles.errorContainer}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, ease: 'easeInOut' }}
        >
          <MotionText
            as="h1"
            text="Oops! Something Went Wrong."
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, ease: 'easeOut' }}
          />
          <MotionText
            as="h5"
            text="We couldn't load your assessment. Please try again."
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: 'easeOut' }}
          />
          <MotionText
            as="p"
            text="If the problem persists, check your internet connection or refresh the page."
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: 'easeOut' }}
          />
          <SystemButton
            variant="primarySkyBlue"
            loading={questionnaireSubmitting}
            fullWidthButton
            onClick={() => window.location.reload()}
            disabled={questionnaireSubmitting}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.75 }}
          >
            Try Again
          </SystemButton>
        </motion.div>
      </ContentWrapper>
    );
  }

  const questions = questionnaire?.questionnairesV2Questions;
  const totalQuestions = questions?.length;
  const question = questions[currentQuestionIndex];
  const answersForQuestion =
    question.questionnairesV2QuestionResponseScales.questionnairesV2QuestionResponseScaleOptions;
  const isFirstQuestion = currentQuestionIndex === 0;
  const isLastQuestion = currentQuestionIndex === totalQuestions - 1;
  const progress = ((currentQuestionIndex + 1) / totalQuestions) * 100;

  const handleSubmitAnswers = () => {
    setQuestionnaireSubmitting(true);
    const answersData = Object.values(answers).reduce(
      (
        acc: Record<string, { questionId: number; answerId: number; answerScore: number }>,
        answer: { questionId: number; answerId: number; answerScore: number },
      ) => {
        acc[answer.questionId.toString()] = {
          questionId: answer.questionId,
          answerId: answer.answerId,
          answerScore: answer.answerScore,
        };

        return acc;
      },
      {} as Record<string, { questionId: number; answerId: number; answerScore: number }>,
    );

    const payload: SubmitAssessmentPayload = {
      questionnaireId: chosenQuestionnaire?.questionnaireId ?? 0,
      answers: answersData,
    };

    submitAssessmentMutation.mutate(payload, {
      onSuccess: (response) => {
        if (response?.success) {
          setShowEWS(response?.responseObject?.ewsTriggered);
          dispatch(
            setNavigationStep(
              response?.responseObject?.ewsTriggered
                ? 'ewsTrigger'
                : companyConfig?.talkToAdvisor
                ? 'talkToAdvisor'
                : 'reflection',
            ),
          );
          dispatch(setCreatedUserAssessment(response?.responseObject));
        } else {
          showToast('Error occurred. Try again', undefined, 'error');
        }
      },
      onError: (error) => {
        showToast('Error occurred. Try again', undefined, 'error');
      },
      onSettled: () => {
        setQuestionnaireSubmitting(false);
      },
    });
  };

  if (question && question?.isBrakeOff && navigationStep === 'scoring') {
    return (
      <ContentWrapper>
        <QuestionnaireBrakeOff
          icon={question?.icon ? `../../../../../images/assessments/${question?.icon}` : null}
          title={question?.questionText}
          htmlDescription={question?.description}
          nextButtonName={question?.buttonText || 'Continue'}
          onNext={() => {
            dispatch(nextQuestion());

            if (isLastQuestion) {
              handleSubmitAnswers();
            }
          }}
          prevButtonEnabled={isLastQuestion}
          onPrevious={() => dispatch(previousQuestion())}
        />
      </ContentWrapper>
    );
  }

  return (
    <>
      {(() => {
        switch (navigationStep) {
          case 'scoring':
            smoothScrollTo(0, 0);

            return (
              <div className={styles.container}>
                <QuestionCard
                  question={{
                    ...question,
                    answers: answersForQuestion.map((answer) => ({
                      id: answer.id,
                      optionText: answer.optionText,
                      score: answer.score,
                    })),
                  }}
                  progress={progress}
                  isFirstQuestion={isFirstQuestion}
                  isLastQuestion={isLastQuestion}
                  onAnswer={(answerId) => {
                    smoothScrollTo(0, 200);
                    dispatch(
                      answerQuestion({ questionId: question.id, answerId: Number(answerId) }),
                    );

                    if (isLastQuestion && !question?.isBrakeOff) {
                      handleSubmitAnswers();
                    }
                  }}
                  onPrevious={() => dispatch(previousQuestion())}
                />
              </div>
            );

          case 'ewsTrigger':
            return (
              <div className={styles.container}>
                <Box className={styles.globalRootCard}>
                  <HelpAndSpeakUp
                    speakUpVisible={showSpeakUp}
                    previewInstantHelp={showWellbeingHub}
                    handleInstantPreview={setShowWellbeingHub}
                    handleSkip={() => {
                      dispatch(
                        setNavigationStep(
                          companyConfig?.talkToAdvisor ? 'talkToAdvisor' : 'reflection',
                        ),
                      );
                      setShowEWS(false);
                    }}
                  />
                </Box>
              </div>
            );

          case 'talkToAdvisor':
            smoothScrollTo(0, 0);

            return (
              <div className={styles.container}>
                <Box className={styles.globalRootCard}>
                  <TalkToAdviserCategorySelection
                    handleSkip={() => dispatch(setNavigationStep('reflection'))}
                  />
                </Box>
              </div>
            );

          case 'reflection':
            smoothScrollTo(0, 0);

            return (
              <div className={styles.container}>
                <Box className={styles.globalRootCard} sx={{ maxWidth: '600px' }}>
                  <Reflection
                    handleStep={() => {
                      // TODO: CHECK RESET STATE NEEDED
                      // dispatch(resetQuestionnaire());
                      if (onComplete) {
                        onComplete();
                      }

                      dispatch(resetQuestionnaire());
                      queryParams.delete('contextType');
                      history.push({ search: queryParams.toString() });
                    }}
                  />
                </Box>
              </div>
            );

          default:
            return null;
        }
      })()}
    </>
  );
};

interface SubmitAssessmentPayload {
  questionnaireId: number;
  answers: Record<
    string,
    {
      questionId: number;
      answerId: number;
      answerScore: number;
    }
  >;
}

SingleQuestionnaire.defaultProps = {
  isOnboarding: false,
  questionnairesError: null,
  questionnairesLoading: false,
  questionsError: null,
  onComplete: undefined,
};
