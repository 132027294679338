import { getIpAddress } from '@api/auth/login';
import { logActionAlertEmail } from '@api/notifications';
import Seo from '@components/SEO';
import ThemeContextProvider from '@context/ThemeContext';
import useGoNative from '@hooks/useGoNative';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { ToastProvider } from '@shared/atoms';
import {
  DesktopFooter,
  Header,
  MobileFooter,
  SecondaryNavigation,
  SideNavigationBar,
} from '@shared/organisms';
import { LogTypes, TwitterCardType } from 'custom.d';
import React, { useEffect } from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { useHistory } from 'react-router';
import styles from './index.module.scss';

export const CoreLayout: React.FunctionComponent<CoreLayoutProps> = (props) => {
  const { children, title, description, name, type, secondaryNavEnabled, navigationType, bgColor } =
    props;
  const { isDesktop, isMobile, isTablet } = useWindowDimensions();
  const isMobileDevice = isMobile || isTablet;
  const urlParams = new URLSearchParams(window.location.search);
  const navigatedBy = urlParams.get('navigatedBy');
  const isMobileApp = useGoNative();
  const history = useHistory();

  useEffect(() => {
    if (navigatedBy && navigatedBy === 'alertEmail') {
      logAlertEmail();
    }
  }, [navigatedBy]);

  const logAlertEmail = async () => {
    const ipAddress = await getIpAddress();
    const isNative = isMobileApp;
    const os = navigator.userAgentData?.platform || '';

    const res = await logActionAlertEmail(
      LogTypes.ACTION_ALERT_EMAIL_CLICKED,
      ipAddress,
      os,
      isNative,
    );
    if (res) {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      params.delete('navigatedBy');
      const newUrl = `${url.pathname}?${params.toString()}`;
      history.replace(newUrl);
    }
  };

  return (
    <ThemeContextProvider>
      <ProSidebarProvider>
        <Seo title={title} description={description} name={name} type={type} />
        <ToastProvider />
        <div className={styles.mainLayout} style={bgColor ? { background: bgColor } : {}}>
          <div className="sticky-top">
            {isDesktop && <Header navigationType={navigationType} />}
            {secondaryNavEnabled && <SecondaryNavigation />}
          </div>
          <div className="boxed">{children}</div>
          <SideNavigationBar />
          {isDesktop && <DesktopFooter />}
          {isMobileDevice && <MobileFooter navigationType={navigationType} />}
        </div>
      </ProSidebarProvider>
    </ThemeContextProvider>
  );
};

CoreLayout.defaultProps = {
  description: '',
  name: '',
  type: 'summary',
  secondaryNavEnabled: false,
  bgColor: null,
};

interface CoreLayoutProps {
  children: React.ReactNode | undefined;
  title: string;
  description?: string;
  name?: string;
  type?: TwitterCardType;
  secondaryNavEnabled?: boolean;
  navigationType: 'default' | 'admin' | 'superAdmin' | 'advisor';
  bgColor?: string | null;
}
