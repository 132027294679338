import { useAppSelector } from '@hooks/useAppSelector';
import { MotionButton, MotionText } from '@shared/atoms';
import React from 'react';
import styles from './index.module.scss';

interface AnswerOptionsProps {
  answers: { id: number; optionText: string; score: number }[];
  onSelectAnswer: (answerId: number) => void;
  className?: string;
  qId: number;
}

export const AnswerOptions: React.FC<AnswerOptionsProps> = ({
  answers,
  onSelectAnswer,
  className,
  qId,
}) => {
  const {
    questionnaire,
    answers: stateAnswers,
    currentQuestionIndex,
  } = useAppSelector((state) => state.questionnaireV2 || {});

  const questions = questionnaire?.questionnairesV2Questions;

  return (
    <div className={className}>
      {answers.map((answer, index) => {
        let isActive = false;
        if (questions) {
          if (questions[currentQuestionIndex]?.id === qId) {
            isActive = Object.values(stateAnswers).some(
              (ans) => ans.answerId === answer.id && ans.questionId === qId,
            );
          }
        }

        return (
          <MotionButton
            key={answer.id}
            whileHover={{ opacity: 0.8 }}
            onClick={() => onSelectAnswer(answer.id)}
            className={isActive ? styles.activeAnswer : ''}
          >
            <span>{String.fromCharCode(65 + index)}</span>&nbsp;&nbsp;&nbsp;&nbsp;
            {answer.optionText}
          </MotionButton>
        );
      })}

      {answers.length === 0 && (
        <MotionText
          as="p"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          text=" There are no answers available for this question."
        />
      )}
    </div>
  );
};

AnswerOptions.defaultProps = {
  className: styles.answersContainer,
};
