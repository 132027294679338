import Img from '@components/Atoms/Img';
import { LearningCarousel } from '@shared/molecules';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useHistory, useLocation } from 'react-router';
import SkyBlueNext from '../../../../assets/images/icons/sky-blue-next.svg';
import styles from './index.module.scss';

export const TagsCarouselSection: React.FunctionComponent<TagsCarouselSectionProps> = ({
  loading,
  tagId,
  title,
  data,
  carouselType,
  onClickNavigation,
  hideTitle,
}) => {
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);

  const navigate = (id: number, categoryName: string) => {
    if (carouselType === 'categoryPosts') {
      urlParams.set('type', 'singleCategoryContent');
      if (urlParams.has('categoryId')) {
        urlParams.set('categoryId', id.toString());
      } else {
        urlParams.append('categoryId', id.toString());
      }

      if (urlParams.has('categoryName')) {
        urlParams.set('categoryName', categoryName);
      } else {
        urlParams.append('categoryName', categoryName);
      }

      history.push({ pathname: '/posts', search: urlParams.toString() });
    }
  };

  return (
    <>
      <div className={styles.container}>
        {!hideTitle && (
          <div
            className={styles.title}
            onClick={() => {
              if (carouselType === 'categoryPosts' && tagId) {
                navigate(tagId, title);
              } else {
                onClickNavigation();
              }
            }}
          >
            <h5>{title}</h5>
            <Img src={SkyBlueNext} alt="HS Next" className={styles.next} />
          </div>
        )}

        <LearningCarousel
          data={data}
          loading={loading}
          carouselType={carouselType}
          navigate={navigate}
          tagDetails={{ tagId, title }}
        />
        {data && data.length === 0 && !loading && (
          <div className={styles.noContent}>
            <p>No content available</p>
          </div>
        )}
      </div>
    </>
  );
};

TagsCarouselSection.defaultProps = {
  loading: false,
  tagId: -1,
  data: [],
  hideTitle: false,
};

interface TagsCarouselSectionProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  loading?: boolean;
  tagId?: number;
  title: string;
  data?: { id: number; innerImageUrl: string; title: string; tagId?: number; tag?: string }[];
  carouselType: string;
  onClickNavigation: () => void;
  hideTitle?: boolean;
}
