import { useAppSelector } from '@hooks/useAppSelector';
import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { RootState } from 'store';

export enum ProtectedTypes {
  JOIN = 'Join',
  REGISTERED = 'Registered',
  AGREE_TERMS = 'Agree_Terms',
  // TODO - add the below logic back in once the onboarding assessment is ready
  // ON_BOARDING_ASSESSMENT = 'On_Boarding_Assessment',
}

const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({
  component: Component,
  RedirectFunction,
  authorizedRoles = [],
  condition,
  ...rest
}) => {
  const isAuthenticated = Boolean(localStorage.getItem('token'));
  const version = localStorage.getItem('v');
  const location = useLocation();
  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user || '{}');
  const parsedUserRole = parsedUser.role ? parsedUser.role : null;

  const isRegistered = Boolean(
    useAppSelector((state: RootState) => state.userStatus.data.isRegistered),
  );
  const isJoined = Boolean(useAppSelector((state: RootState) => state.userStatus.data.isJoined));
  const isAgreed = Boolean(useAppSelector((state: RootState) => state.userStatus.data.isAgreed));
  const statusLoaded = Boolean(useAppSelector((state: RootState) => state.userStatus.isLoaded));
  const companyConfigsLoaded = Boolean(
    useAppSelector((state: RootState) => state.companyConfig.isLoaded),
  );

  const isOnboardingCompleted = Boolean(
    useAppSelector((state: RootState) => state.userStatus.data.isOnboardCompleted),
  );

  const locationChecks = '/login';

  if (!isAuthenticated || version !== '2.0') {
    if (location.pathname !== locationChecks) {
      localStorage.setItem('redirectPath', location.pathname + location.search);
    }

    return <Redirect to="/login" />;
  }

  // **Check:** if the user's role is authorized for this route
  if (authorizedRoles.length > 0 && !authorizedRoles.includes(parsedUserRole)) {
    return <Redirect to="/404" />;
  }

  // **Check:** If `condition` is provided and is `false`, redirect to `/404`
  if (statusLoaded && companyConfigsLoaded && condition !== undefined && !condition) {
    return <Redirect to="/404" />;
  }

  switch (RedirectFunction) {
    case ProtectedTypes.JOIN:
      if (!isRegistered) {
        return <Redirect to="/complete-signup" />;
      }

      if (!isAgreed) {
        return <Redirect to="/welcome" />;
      }

      if (isJoined) {
        return <Redirect to="/" />;
      }

      // TODO - add the below logic back in once the onboarding assessment is ready
      // if (isJoined && !isOnboardingCompleted) {
      //   return <Redirect to="/onboarding-assessment" />;
      // }

      // if (isJoined && isOnboardingCompleted) {
      //   return <Redirect to="/" />;
      // }

      return <Route {...rest} render={(props: any) => <Component {...props} />} />;
      break;

    case ProtectedTypes.AGREE_TERMS:
      if (!isRegistered) {
        return <Redirect to="/complete-signup" />;
      }

      if (isAgreed) {
        return <Redirect to="/introduction" />;
      }

      return <Route {...rest} render={(props: any) => <Component {...props} />} />;
      break;

    case ProtectedTypes.REGISTERED:
      if (isRegistered) {
        return <Redirect to="/welcome" />;
      }

      return <Route {...rest} render={(props: any) => <Component {...props} />} />;
      break;

    // TODO - add the below logic back in once the onboarding assessment is ready
    // case ProtectedTypes.ON_BOARDING_ASSESSMENT:
    //   if (isRegistered && isAgreed && isJoined && !isOnboardingCompleted) {
    //     return <Redirect to="/onboarding-assessment" />;
    //   }

    //   return <Route {...rest} render={(props: any) => <Component {...props} />} />;
    //   break;

    default:
      if (!isRegistered) {
        return <Redirect to="/complete-signup" />;
      }

      if (!isAgreed) {
        return <Redirect to="/welcome" />;
      }

      if (!isJoined) {
        return <Redirect to="/introduction" />;
      }

      return <Route {...rest} render={(props: any) => <Component {...props} />} />;
  }
};

ProtectedRoute.defaultProps = {
  RedirectFunction: 'default',
  authorizedRoles: [],
  condition: undefined,
};

interface ProtectedRouteProps extends RouteProps {
  component: FunctionComponent;
  RedirectFunction?: any;
  authorizedRoles?: string[];
  condition?: boolean;
}

export default ProtectedRoute;
