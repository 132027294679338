import { LineProgressBar, MotionImage, MotionText } from '@shared/atoms';
import { AnswerOptions, QuestionNavigation } from '@shared/molecules';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styles from './index.module.scss';

interface QuestionCardProps {
  question: {
    id: number;
    questionnaireId: number;
    categoryId: number;
    responseScaleId: number;
    questionText: string;
    orderId: number;
    required: boolean;
    icon: string | null;
    buttonText: string | null;
    description: string | null;
    isBrakeOff: boolean;
    answers: { id: number; optionText: string; score: number }[];
  };
  progress: number;
  isLastQuestion: boolean;
  isFirstQuestion: boolean;
  onAnswer: (answerId: number) => void;
  onPrevious: () => void;
  className?: string;
}

export const QuestionCard: React.FC<QuestionCardProps> = ({
  question,
  progress,
  isFirstQuestion,
  isLastQuestion,
  onAnswer,
  onPrevious,
  className,
}) => (
  <div className={styles.container}>
    <div className={className}>
      <LineProgressBar progress={progress} />
      {question?.icon && (
        <div className={styles.iconCircle}>
          <MotionImage
            src={`/images/assessments/${question.icon}`}
            alt="Question Image"
            className={styles.topIconImage}
            animation={{
              initial: { opacity: 0, scale: 0.8 },
              animate: { opacity: 1, scale: 1 },
              exit: { opacity: 0, scale: 0.8 },
              transition: { duration: 0.5 },
            }}
            skeletonWidth="50px"
            skeletonHeight="50px"
            shape="circle"
          />
        </div>
      )}

      <MotionText as="h3" text="Over the last 2 weeks" className={styles.subTitle} />
      <AnimatePresence mode="wait">
        <motion.div
          key={question.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.1, duration: 0.3, ease: 'easeOut' } }}
          exit={{ opacity: 0, y: -10, transition: { duration: 0.2 } }}
          className={styles.questionContainer}
        >
          <MotionText as="h3" text={question.questionText} className={styles.question} />
          <AnswerOptions answers={question.answers} onSelectAnswer={onAnswer} qId={question.id} />
          <QuestionNavigation
            onPrevious={onPrevious}
            onNext={() => {
              if (!isLastQuestion) onAnswer(0);
            }}
            showPrevious={!isFirstQuestion}
          />
        </motion.div>
      </AnimatePresence>
    </div>
  </div>
);

QuestionCard.defaultProps = {
  className: styles.card,
};
