import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/material';
import { MotionButton, MotionImage, MotionText, SanitizedText } from '@shared/atoms';
import { SystemButton } from '@shared/molecules';
import React from 'react';
import styles from './index.module.scss';

interface Props {
  title?: string | null;
  htmlDescription?: string | null;
  icon?: string | null;
  nextButtonName?: string | null;
  onNext: () => void;
  prevButtonEnabled: boolean;
  onPrevious?: () => void;
}

export const QuestionnaireBrakeOff: React.FC<Props> = ({
  title,
  htmlDescription,
  icon,
  nextButtonName,
  onNext,
  prevButtonEnabled,
  onPrevious,
}) => (
  <div className={styles.container}>
    {icon && (
      <div className={styles.iconCircle}>
        <MotionImage
          src={icon}
          alt="Questionnaire Image"
          className={styles.topIconImage}
          animation={{
            initial: { opacity: 0, scale: 0.8 },
            animate: { opacity: 1, scale: 1 },
            exit: { opacity: 0, scale: 0.8 },
            transition: { duration: 0.5 },
          }}
          skeletonWidth="50px"
          skeletonHeight="50px"
          shape="circle"
        />
      </div>
    )}

    <div>
      <MotionText
        as="h2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        text={title || "You're Making Great Progress! 🌟"}
      />
      <SanitizedText
        as="div"
        content={
          htmlDescription ||
          "You've completed the first part of your assessment. 96% of people report feeling more self-aware after this process."
        }
      />
    </div>

    <div className={styles.navigationButtons}>
      <SystemButton
        variant="primarySkyBlue"
        fullWidthButton
        onClick={onNext}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.75 }}
      >
        {nextButtonName || 'Continue Your Journey'}
      </SystemButton>

      {prevButtonEnabled && onPrevious && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <MotionButton onClick={onPrevious} className={styles.backBtn}>
            <ArrowBackIcon />
          </MotionButton>
        </Box>
      )}
    </div>
  </div>
);

QuestionnaireBrakeOff.defaultProps = {
  title: null,
  htmlDescription: null,
  icon: null,
  nextButtonName: null,
  onPrevious: undefined,
};
