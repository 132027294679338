import homeActive from '../assets/images/icons/home-active.svg';
import homeInactive from '../assets/images/icons/home-inactive.svg';
import learnActive from '../assets/images/icons/learn-active.svg';
import learnInactive from '../assets/images/icons/learn-inactive.svg';
import expertsActive from '../assets/images/icons/experts-active.svg';
import expertsInactive from '../assets/images/icons/experts-inactive.svg';
import analyticsActive from '../assets/images/icons/analytics-active.svg';
import analyticsInactive from '../assets/images/icons/analytics-inactive.svg';
import commentsActive from '../assets/images/icons/comments-active.svg';
import commentsInactive from '../assets/images/icons/comments-inactive.svg';
import usersActive from '../assets/images/icons/users-active.svg';
import usersInactive from '../assets/images/icons/users-inactive.svg';
import actionsActive from '../assets/images/icons/actions-active.svg';
import actionsInactive from '../assets/images/icons/actions-inactive.svg';
import starsActive from '../assets/images/icons/stars-active.svg';
import starsInactive from '../assets/images/icons/stars-inactive.svg';
import wellbeingActive from '../assets/images/icons/wellbeing-hub-active.svg';
import wellbeingInactive from '../assets/images/icons/wellbeing-hub-inactive.svg';

const menuConfig = {
  superAdmin: [
    {
      name: 'Home',
      mobileName: 'Home',
      path: '/management/homepage',
      exact: true,
      icon: homeInactive,
      activeIcon: homeActive,
    },
    {
      name: 'Learning',
      mobileName: 'Learn',
      path: '/management/learning',
      exact: false,
      icon: learnInactive,
      activeIcon: learnActive,
    },
    {
      name: 'Experts',
      mobileName: 'Experts',
      path: '/management/experts',
      exact: false,
      icon: expertsInactive,
      activeIcon: expertsActive,
    },
    {
      name: 'Bookings',
      mobileName: 'Bookings',
      path: '/management/booking-requests',
      exact: false,
      icon: expertsInactive,
      activeIcon: expertsActive,
    },
  ],
  admin: [
    {
      name: 'Home',
      mobileName: 'Home',
      path: '/employer/homepage',
      exact: true,
      icon: homeInactive,
      activeIcon: homeActive,
    },
    {
      name: 'Analytics',
      mobileName: 'Analytics',
      path: '/employer/analytics',
      exact: false,
      icon: analyticsInactive,
      activeIcon: analyticsActive,
    },
    {
      name: 'Comments',
      mobileName: 'Comments',
      path: '/employer/comments',
      exact: false,
      icon: commentsInactive,
      activeIcon: commentsActive,
    },
    {
      name: 'Users',
      mobileName: 'Users',
      path: '/employer/users',
      exact: false,
      icon: usersInactive,
      activeIcon: usersActive,
    },
  ],
  advisor: [
    {
      name: 'Participant Report',
      mobileName: 'Report',
      path: '/advisor/participant-report',
      exact: true,
      icon: homeInactive,
      activeIcon: homeActive,
    },
  ],
  default: [
    {
      name: 'Home',
      mobileName: 'Home',
      path: '/',
      exact: true,
      icon: homeInactive,
      activeIcon: homeActive,
    },
    {
      name: 'Scores',
      mobileName: 'Scores',
      path: '/stars',
      exact: false,
      icon: starsInactive,
      activeIcon: starsActive,
    },
    {
      name: 'Plans',
      mobileName: 'Plans',
      path: '/improve-plans',
      exact: false,
      icon: actionsInactive,
      activeIcon: actionsActive,
    },
    {
      name: 'Hub',
      mobileName: 'Hub',
      path: '/wellbeing',
      exact: false,
      icon: wellbeingInactive,
      activeIcon: wellbeingActive,
    },
    {
      name: 'Learn',
      mobileName: 'Learn',
      path: '/posts',
      exact: false,
      icon: learnInactive,
      activeIcon: learnActive,
    },
    {
      name: 'Experts',
      mobileName: 'Experts',
      path: '/experts',
      exact: false,
      icon: expertsInactive,
      activeIcon: expertsActive,
    },
  ],
};

export default menuConfig;
