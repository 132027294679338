import { getIpAddress } from '@api/auth/login';
import { logLearning } from '@api/learning';
import useGoNative from '@hooks/useGoNative';
import { Skeleton } from '@mui/material';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styles from './index.module.scss';

export const LearningArticleCard: React.FunctionComponent<LearningArticleCardProps> = ({
  loading,
  data,
  sectionType,
  navigate,
  tagDetails,
  navigateTo,
}) => {
  const isMobileApp = useGoNative();
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const navigationType = urlParams.get('type');
  const catId = urlParams.get('categoryId');
  const catName = urlParams.get('categoryName');
  const [imgLoading, setImgLoading] = useState(true);
  const allBlogPostsId = -999;

  const handleImageLoad = () => {
    setImgLoading(false);
  };

  const navigation = (id: number, categoryName: string) => {
    if (sectionType === 'categories') {
      if (navigationType === 'learningHome') {
        urlParams.set('type', 'singleCategoryContent');
      } else {
        urlParams.set('type', 'singleCategoryContentTags');
      }

      if (urlParams.has('categoryId')) {
        urlParams.set('categoryId', id.toString());
      } else {
        urlParams.append('categoryId', id.toString());
      }

      if (urlParams.has('categoryName')) {
        urlParams.set('categoryName', categoryName);
      } else {
        urlParams.append('categoryName', categoryName);
      }

      history.push({ search: urlParams.toString() });
    }
  };

  if (data) {
    return (
      <div
        className={styles.learningItem}
        onClick={() => {
          if (sectionType === 'categories' && navigate) {
            navigation(data.id, data.title);
          } else if (sectionType === 'posts' || sectionType === 'categoryPosts') {
            if (tagDetails && tagDetails.tagId && tagDetails.tagId !== -1) {
              history.push(
                `/post/${data.id}?categoryId=${tagDetails.tagId}&categoryName=${tagDetails.title} `,
              );
            } else if (navigationType === 'singleCategoryContentTags' && catId && catName) {
              history.push(`/post/${data.id}?categoryId=${catId}&categoryName=${catName} `);
            } else {
              history.push(`/post/${data.id}`);
            }
          } else {
            history.push(`/posts?type=learningHome`);
          }
        }}
      >
        <div className={styles.learningItemInner}>
          <div className={styles.learningItemInnerImage}>
            {imgLoading && (
              <Skeleton variant="rectangular" width="100%" height="100%" className={styles.image} />
            )}
            <img
              src={data.innerImageUrl}
              alt={data.title}
              className={styles.image}
              onLoad={handleImageLoad}
              style={{ display: imgLoading ? 'none' : 'block' }}
            />
          </div>
          <div className={styles.learningItemInnerContent}>
            <div dangerouslySetInnerHTML={{ __html: data.title }} />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

LearningArticleCard.defaultProps = {
  loading: false,
  data: null,
  navigate: () => null,
  tagDetails: { tagId: -1, title: '' },
  navigateTo: 'oldPosts',
};

interface LearningArticleCardProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  loading?: boolean;
  data?: { id: number; innerImageUrl: string; title: string } | null;
  sectionType: string;
  navigate?: (id: number, title: string) => void;
  tagDetails?: { tagId: number | undefined; title: string | undefined };
  navigateTo?: string;
}
