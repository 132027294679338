import DefaultButton from '@components/Atoms/DefaultButton';
import ResponseModal from '@components/ModalContent/CommentResponse';
import ViewCommentModal from '@components/ModalContent/ViewComment';
import { newFormatDate } from '@utils/date.util';
import { truncateString } from '@utils/string.util';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import styles from './ResponsesActionCard.module.scss';

const ResponsesActionCard: React.FunctionComponent<ActionsCardProps> = (props) => {
  const {
    focusArea,
    focusAreaColor,
    questionnaireName,
    action,
    score,
    completed,
    createdDate,
    addToCompleted,
    saveResponseProps,
    categoryName,
  } = props;
  const [actionsCheckbox, setActionCheckbox] = useState(false);
  const [openViewCommentsModal, setOpenViewCommentsModal] = useState(false);
  const [openResponseModal, setOpenResponseModal] = useState(false);

  const styleClasses = classNames([
    styles.hsActionsCard,
    focusAreaColor === '#BEAAF3' && styles.calmLilac,
    focusAreaColor === '#019DF2' && styles.skyBlue,
    focusAreaColor === '#FCD300' && styles.sunYellow,
    focusAreaColor === '#8EEA36' && styles.zingyGreen,
    focusAreaColor === '#06BF97' && styles.aquaGreen,
    focusAreaColor === '#FF8A4A' && styles.warmOrange,
  ]);

  const markAsDone = () => {
    setActionCheckbox(true);
    addToCompleted();
  };

  const isModalView = action.length >= 75;

  const handleOpenViewCommentModal = useCallback(() => {
    setOpenViewCommentsModal(true);
  }, [action]);

  const handleCloseViewCommentModal = useCallback(() => {
    setOpenViewCommentsModal(false);
  }, []);

  const handleOpenResponseModal = useCallback(() => {
    setOpenResponseModal(true);
  }, []);

  const handleCloseResponseModal = useCallback(() => {
    setOpenResponseModal(false);
  }, []);

  const handleSubmitComment = useCallback(
    (data: any, methods: any) => {
      setOpenResponseModal(false);
      saveResponseProps(data.content);
      methods.reset();
    },
    [saveResponseProps],
  );

  return (
    <div className={styleClasses}>
      <div className={styles.hsActionsCardTitle}>
        <div className={styles.dateContainer}>
          <p className={styles.formattedDate}>{newFormatDate(createdDate)}</p>
          <p className={styles.focusArea}>{focusArea}</p>
        </div>

        <p className={styles.categorySection}>
          <p>{categoryName}</p>
          <span>{score}</span>
        </p>
      </div>

      <div className={styles.inputContainer}>
        <div className={styles.hsActionsCardTitle}>
          <p className={styles.truncateSection}>
            {truncateString(action, 35)}
            {isModalView && (
              <a className={styles.viewMore} onClick={handleOpenViewCommentModal}>
                View More
              </a>
            )}
          </p>
        </div>

        <div className={styles.buttonContainer}>
          <DefaultButton variant="primary" onClick={handleOpenResponseModal}>
            Update Respond
          </DefaultButton>
        </div>

        <ResponseModal
          title="Respond to user"
          isOpen={openResponseModal}
          onClose={handleCloseResponseModal}
          onSave={handleSubmitComment}
          preResponse={action}
        />

        <ViewCommentModal
          title={focusArea}
          isOpen={openViewCommentsModal}
          onClose={handleCloseViewCommentModal}
        >
          {action}
        </ViewCommentModal>
      </div>
    </div>
  );
};

type ActionsCardProps = {
  focusArea: string;
  focusAreaColor: any;
  action: string;
  questionnaireName: string;
  score: number;
  completed: boolean;
  createdDate: string;
  addToCompleted: () => void;
  saveResponseProps: (thoughtResponseText: string) => void;
  categoryName: string;
};

export default ResponsesActionCard;
