import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import { PaginationInterface } from '@interfaces/index';
import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useHistory, useLocation } from 'react-router';
import SkyBluePrevious from '../../../../assets/images/icons/sky-blue-previous.svg';
import { LearningArticleCard } from '../../../molecules/Learning/LearningArticleCard/LearningArticleCard';
import styles from './index.module.scss';

export const FilteredArticlesSection: React.FunctionComponent<FilteredArticlesSectionProps> = ({
  loading,
  title,
  data,
  onClickNavigateBack,
  sectionType,
  paginationProps,
  handleLoadMore,
  handleLoadMoreLoading,
}) => {
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const navigationType = urlParams.get('type');
  const catId = urlParams.get('categoryId');
  const catName = urlParams.get('categoryName');

  const navigate = (id: number, categoryName: string) => {
    if (sectionType === 'categories') {
      urlParams.set('type', 'singleCategoryContentTags');
      if (urlParams.has('categoryId')) {
        urlParams.set('categoryId', id.toString());
      } else {
        urlParams.append('categoryId', id.toString());
      }

      if (urlParams.has('categoryName')) {
        urlParams.set('categoryName', categoryName);
      } else {
        urlParams.append('categoryName', categoryName);
      }

      history.push({ search: urlParams.toString() });
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <h5>{title}</h5>
          <div className={styles.navigateBack} onClick={onClickNavigateBack}>
            <Img src={SkyBluePrevious} alt="HS Previous" className={styles.previous} />
            {navigationType === 'singleCategoryContentTags' ? <h5>Categories</h5> : <h5>Learn</h5>}
          </div>
        </div>

        <Grid container spacing={4} className={styles.learningItemsContainer}>
          {loading && !handleLoadMoreLoading
            ? Array.from({ length: 9 }).map((_, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={277}
                    className={styles.learningLoadingItem}
                  />
                </Grid>
              ))
            : data &&
              data.map((dt, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <LearningArticleCard
                    data={dt}
                    navigate={navigate}
                    sectionType={sectionType}
                    tagDetails={{ tagId: Number(catId) || -1, title: catName || '' }}
                    navigateTo="newPosts"
                  />
                </Grid>
              ))}
          {handleLoadMoreLoading &&
            Array.from({ length: 9 - ((data?.length || 0) % 9) }).map((_, index) => (
              <Grid item xs={12} md={4} key={`skeleton-${index}`}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={277}
                  className={styles.learningLoadingItem}
                />
              </Grid>
            ))}
        </Grid>

        {navigationType === 'latestContents' && paginationProps?.hasNext && !loading && (
          <div className={styles.loadMoreBtn}>
            <DefaultButton variant="darken" onClick={() => handleLoadMore && handleLoadMore()}>
              Load more
            </DefaultButton>
          </div>
        )}

        {data && data.length === 0 && !loading && (
          <div className={styles.noContent}>
            <p>No content available</p>
          </div>
        )}
      </div>
    </>
  );
};

FilteredArticlesSection.defaultProps = {
  loading: false,
  data: [],
  paginationProps: {
    page: 0,
    perPage: 0,
    pageDataCount: 0,
    totalDataCount: 0,
    totalPages: 0,
    hasNext: false,
    hasPrevious: false,
    remaining: 0,
  },
  handleLoadMore: undefined,
  handleLoadMoreLoading: false,
};

interface FilteredArticlesSectionProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  loading?: boolean;
  title: string;
  data?: { id: number; innerImageUrl: string; title: string; tagId?: number; tag?: string }[];
  onClickNavigateBack: () => void;
  sectionType: string;
  paginationProps?: PaginationInterface;
  handleLoadMore?: () => void;
  handleLoadMoreLoading?: boolean;
}
