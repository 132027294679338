import {
  addLearningContentProperties,
  getCompaniesWithLearnings,
  getLearningQuestions,
  getLearningsByTitle,
} from '@api/learningContent';
import { learningContentApiHandler } from '@api/learningContentApiHandler';
import { getAllTagsByContent } from '@api/learningTags';
import NoPreviewImage from '@assets/images/no-preview.png';
import AutocompleteComponent from '@components/Atoms/Autocomplete';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import Modal from '@components/Atoms/Modal';
import ManageCategories from '@components/ModalContent/ManageCategories';
import MainLayout from '@layouts/MainLayout';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Card, Checkbox, Chip, CircularProgress, Grid } from '@mui/material';
import { SystemButton } from '@shared/molecules';
import { useMutation } from '@tanstack/react-query';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import styles from './index.module.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EmployerLearningConfig: FunctionComponent = () => {
  const [learningContentOptions, setLearningContentOptions] = useState([]);
  const [selectedLearningOpt, setSelectedLearningOpt] = useState<{
    label: string;
    value: number;
    imageUrl?: string;
  } | null>(null);
  const [mainDrives, setMainDrives] = useState<ModifiedLearningDriver[] | []>([]);
  const [subDrives, setSubDrives] = useState<ModifiedLearningDriver[] | []>([]);
  const [categories, setCategories] = useState<ModifiedTag[] | []>([]);
  const [companies, setCompanies] = useState<ModifiedCompany[] | []>([]);
  const [loadingRefetching, setLoadingRefetching] = useState(false);
  const [selectedMainDrives, setSelectedMainDrives] = useState<ModifiedLearningDriver[] | []>([]);
  const [selectedSubDrives, setSelectedSubDrives] = useState<ModifiedLearningDriver[] | []>([]);
  const [selectedCategories, setSelectedCategories] = useState<ModifiedTag[] | []>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<ModifiedCompany[] | []>([]);
  const [categoryManageDialogOpen, setCategoryManageDialogOpen] = useState<boolean>(false);
  const [loadingModUpdate, setLoadingModUpdate] = useState<boolean>(false);

  const { mutateAsync: feedLearningsToDB, isPending } = useMutation({
    mutationFn: learningContentApiHandler.feedLearningsToDB,
  });

  useEffect(() => {
    getLearnings();
  }, []);

  const getLearnings = async () => {
    const res = await getLearningsByTitle('');

    const modRes = res.map((item: { title: string; id: number; imageUrl: string }) => ({
      label: item.title,
      value: item.id,
      imageUrl: item.imageUrl,
    }));

    setLearningContentOptions(modRes);
  };

  const refetchAllLearningsToDB = async () => {
    setLoadingRefetching(true);

    try {
      const response = await feedLearningsToDB();
      if (response?.success) {
        toast.success(`Successfully updated ${response?.responseObject?.count} learning articles`);
      } else {
        toast.error(`Failed: ${response?.message}`);
      }
    } catch (error) {
      toast.error('Failed to update learning articles. Please try again later.');
    } finally {
      setLoadingRefetching(false);
    }
  };

  const decodeHTMLEntities = (str: string) => {
    const element = document.createElement('textarea');
    element.innerHTML = str;

    return element.value;
  };

  useEffect(() => {
    if (selectedLearningOpt && selectedLearningOpt?.value > 0) {
      getDataFromContent();
    }
  }, [selectedLearningOpt]);

  const filteredDrives = useMemo(() => {
    if (selectedLearningOpt && selectedLearningOpt.value > 0) {
      const filteredMain = mainDrives.filter(
        (v) => v.assignedLearningContent === selectedLearningOpt.value,
      );
      const filteredSub = subDrives.filter(
        (v) => v.assignedLearningContent === selectedLearningOpt.value,
      );
      const filteredCategories = categories.filter(
        (v) => v.assignedLearningContent === selectedLearningOpt.value,
      );
      const filteredCompanies = companies.filter(
        (v) => v.assignedLearningContent === selectedLearningOpt.value,
      );

      return {
        main: filteredMain,
        sub: filteredSub,
        categories: filteredCategories,
        companies: filteredCompanies,
      };
    }

    return {
      main: [],
      sub: [],
      categories: [],
      companies: [],
    };
  }, [selectedLearningOpt, mainDrives, subDrives, categories, companies]);

  useEffect(() => {
    setSelectedMainDrives(filteredDrives.main);
    setSelectedSubDrives(filteredDrives.sub);
    setSelectedCategories(filteredDrives.categories);
    setSelectedCompanies(filteredDrives.companies);
  }, [
    filteredDrives,
    setSelectedMainDrives,
    setSelectedSubDrives,
    setSelectedCategories,
    setSelectedCompanies,
  ]);

  const modSave = async (): Promise<void> => {
    try {
      setLoadingModUpdate(true);

      const md: number[] = selectedMainDrives.map((item) => item.value);
      const sd: number[] = selectedSubDrives.map((item) => item.value);
      const cat: number[] = selectedCategories.map((item) => item.value);
      const comp: number[] = selectedCompanies.map((item) => item.value);

      const lId: number | undefined = selectedLearningOpt?.value;

      if (lId) {
        const res: LearningContentResponse = await addLearningContentProperties(
          lId,
          md,
          sd,
          cat,
          comp,
        );
        if (res.success) {
          toast.success('Successfully updated learning content properties');
        } else {
          toast.error('Failed to update learning content properties');
        }
      } else {
        toast.error('Please select a learning content');
      }
    } catch (error) {
      toast.error(
        `An error occurred: ${(error as Error).message}` ||
          'Failed to update learning content properties',
      );
    } finally {
      setLoadingModUpdate(false);
    }
  };

  const getDataFromContent = async () => {
    if (selectedLearningOpt && selectedLearningOpt?.value) {
      const shortStarOnly = await getLearningQuestions(selectedLearningOpt?.value, true);
      const modifiedShortStars = shortStarOnly.map((item: ModifiedLearningQuestion) => ({
        label: item?.questionText,
        value: item.id,
        questionInfo: item?.questionInfo,
        generalHappinessQuestion: item?.generalHappinessQuestion,
        learningContentAssigned: item?.learningContentAssigned,
        assignedLearningContent: item?.assignedLearningContent?.learningId,
      }));
      setMainDrives(modifiedShortStars);

      const otherQuestions = await getLearningQuestions(selectedLearningOpt?.value, false);
      const modifiedOtherQues = otherQuestions.map((item: ModifiedLearningQuestion) => ({
        label: item?.questionText,
        value: item.id,
        questionInfo: item?.questionInfo,
        generalHappinessQuestion: item?.generalHappinessQuestion,
        learningContentAssigned: item?.learningContentAssigned,
        assignedLearningContent: item?.assignedLearningContent?.learningId,
      }));
      setSubDrives(modifiedOtherQues);

      fetchTags();

      const allCompanies = await getCompaniesWithLearnings(selectedLearningOpt?.value);
      const modifiedCompanies = allCompanies
        ?.map((item: any) => ({
          label: item?.companyName,
          value: item?.id,
          imageUrl: item?.companyLogo
            ? `../../../../images/logos/${item.companyLogo}`
            : NoPreviewImage,
          assignedLearningContent: item?.assignedContent?.learningId,
          learningContentAssigned: item?.learningContentAssigned,
        }))
        ?.sort((a: { label: string }, b: { label: string }) => a?.label?.localeCompare(b?.label));

      setCompanies(modifiedCompanies);
    }
  };

  const fetchTags = async () => {
    if (selectedLearningOpt?.value) {
      const tags = await getAllTagsByContent(selectedLearningOpt?.value);
      const modifiedTags = tags.map(
        (item: {
          tag: string;
          id: number;
          thumbnailImageUrl: string;
          orderId: number;
          assignedContent: { contentId: any };
          learningContentAssigned: boolean;
        }) => ({
          label: item?.tag,
          value: item?.id,
          imageUrl: item?.thumbnailImageUrl,
          orderId: item?.orderId,
          assignedLearningContent: item?.assignedContent?.contentId,
          learningContentAssigned: item?.learningContentAssigned,
        }),
      );

      setCategories(modifiedTags);
    }
  };

  return (
    <MainLayout
      title="Admin Learning Management"
      useSuperAdminNav
      isSuperAdminPage
      secondaryNavEnabled
    >
      <div className={styles.mainContainer}>
        {categoryManageDialogOpen && (
          <div className={styles.overlay}>
            <Modal
              open={categoryManageDialogOpen}
              setOpen={setCategoryManageDialogOpen}
              className={styles.modalRoot}
            >
              <ManageCategories
                onClose={() => setCategoryManageDialogOpen(false)}
                refetchTags={fetchTags}
              />
            </Modal>
          </div>
        )}
        <div className={styles.topSection}>
          <h2 className={styles.mainTitle}>Learning Content Management</h2>
          <div className={styles.topSectionButtons}>
            <SystemButton
              variant="outlinedPrimarySkyBlue"
              onClick={() => setCategoryManageDialogOpen(true)}
            >
              Manage categories
            </SystemButton>
            <SystemButton
              variant="primarySkyBlue"
              onClick={refetchAllLearningsToDB}
              disabled={loadingRefetching}
            >
              Fetch content
              {loadingRefetching && (
                <CircularProgress color="inherit" size={20} className={styles.loadingSpinner} />
              )}
            </SystemButton>
          </div>
        </div>

        <div className={styles.selectionContainer}>
          <AutocompleteComponent
            options={learningContentOptions}
            label="Select learning content"
            isError={false}
            onChange={(event: any, value: any) => setSelectedLearningOpt(value)}
            getOptionLabel={(option: { label: string }) => decodeHTMLEntities(option.label)}
            renderOption={(
              props: JSX.IntrinsicAttributes &
                React.ClassAttributes<HTMLLIElement> &
                React.LiHTMLAttributes<HTMLLIElement>,
              option: {
                value: number;
                imageUrl: string;
                label: string;
              },
            ) => (
              <li {...props}>
                {option.imageUrl ? (
                  <Img
                    src={option.imageUrl}
                    alt={option.label}
                    className={styles.autocompleteImageOption}
                    onError={(e: { target: HTMLImageElement }) => {
                      (e.target as HTMLImageElement).src = NoPreviewImage;
                    }}
                  />
                ) : (
                  <div className={styles.autocompleteImageOption} />
                )}
                <div className={styles.contentAutocompleteOption}>
                  <span>
                    #{option.value} <h6 dangerouslySetInnerHTML={{ __html: option.label }} />
                  </span>
                </div>
              </li>
            )}
          />

          {!selectedLearningOpt ? (
            <h6>Please select learning content to continue.</h6>
          ) : (
            <div>
              <Card className={styles.cardContainer}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <h5>Main drivers</h5>

                    <AutocompleteComponent
                      options={mainDrives}
                      label="Select main drivers"
                      isError={false}
                      value={selectedMainDrives}
                      onChange={(event: any, value: any) => setSelectedMainDrives(value)}
                      renderTags={(value: any, getTagProps: any) =>
                        value.map((option: { label: string }, index: number) => (
                          <Chip
                            key={index}
                            variant="filled"
                            label={option.label}
                            size="small"
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderOption={(
                        props: JSX.IntrinsicAttributes &
                          React.ClassAttributes<HTMLLIElement> &
                          React.LiHTMLAttributes<HTMLLIElement>,
                        option: {
                          label: string;
                        },
                        { selected }: any,
                      ) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      )}
                      renderInputProps={{
                        placeholder: 'Main drivers',
                      }}
                      multiple
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <h5>Sub drivers</h5>
                    <AutocompleteComponent
                      options={subDrives}
                      label="Select sub drivers"
                      isError={false}
                      value={selectedSubDrives}
                      onChange={(event: any, value: any) => setSelectedSubDrives(value)}
                      renderTags={(value: any, getTagProps: any) =>
                        value.map((option: { label: string }, index: number) => (
                          <Chip
                            key={index}
                            variant="filled"
                            label={option.label}
                            size="small"
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderOption={(
                        props: JSX.IntrinsicAttributes &
                          React.ClassAttributes<HTMLLIElement> &
                          React.LiHTMLAttributes<HTMLLIElement>,
                        option: {
                          label: string;
                        },
                        { selected }: any,
                      ) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      )}
                      renderInputProps={{
                        placeholder: 'Sub drivers',
                      }}
                      multiple
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <h5>Categories</h5>
                    <AutocompleteComponent
                      options={categories}
                      label="Select categories/tags"
                      value={selectedCategories}
                      isError={false}
                      onChange={(event: any, value: any) => setSelectedCategories(value)}
                      renderTags={(value: any, getTagProps: any) =>
                        value.map((option: { label: string }, index: number) => (
                          <Chip
                            key={index}
                            variant="filled"
                            label={option.label}
                            size="small"
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderOption={(
                        props: JSX.IntrinsicAttributes &
                          React.ClassAttributes<HTMLLIElement> &
                          React.LiHTMLAttributes<HTMLLIElement>,
                        option: {
                          value: number;
                          imageUrl: string;
                          label: string;
                        },
                      ) => (
                        <li {...props}>
                          {option.imageUrl ? (
                            <Img
                              src={option.imageUrl}
                              alt={option.label}
                              className={styles.autocompleteImageOption}
                              onError={(e: { target: HTMLImageElement }) => {
                                (e.target as HTMLImageElement).src = NoPreviewImage;
                              }}
                            />
                          ) : (
                            <div className={styles.autocompleteImageOption} />
                          )}
                          <div className={styles.contentAutocompleteOption}>
                            <span>
                              #{option.value}{' '}
                              <h6 dangerouslySetInnerHTML={{ __html: option.label }} />
                            </span>
                          </div>
                        </li>
                      )}
                      renderInputProps={{
                        placeholder: 'Categories',
                      }}
                      multiple
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <h5>Companies</h5>
                    <AutocompleteComponent
                      options={companies}
                      label="Select companies"
                      isError={false}
                      value={selectedCompanies}
                      onChange={(event: any, value: any) => setSelectedCompanies(value)}
                      renderTags={(value: any, getTagProps: any) =>
                        value.map((option: { label: string }, index: number) => (
                          <Chip
                            key={index}
                            variant="filled"
                            label={option.label}
                            size="small"
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderOption={(
                        props: JSX.IntrinsicAttributes &
                          React.ClassAttributes<HTMLLIElement> &
                          React.LiHTMLAttributes<HTMLLIElement>,
                        option: {
                          value: number;
                          imageUrl: string;
                          label: string;
                        },
                      ) => (
                        <li {...props}>
                          {option.imageUrl ? (
                            <Img
                              src={option.imageUrl}
                              alt={option.label}
                              className={styles.autocompleteImageOption}
                              onError={(e: { target: HTMLImageElement }) => {
                                (e.target as HTMLImageElement).src = NoPreviewImage;
                              }}
                            />
                          ) : (
                            <div className={styles.autocompleteImageOption} />
                          )}
                          <div className={styles.contentAutocompleteOption}>
                            <span>
                              #{option.value}{' '}
                              <h6 dangerouslySetInnerHTML={{ __html: option.label }} />
                            </span>
                          </div>
                        </li>
                      )}
                      renderInputProps={{
                        placeholder: 'Companies',
                      }}
                      multiple
                    />
                  </Grid>
                </Grid>

                <div className={styles.saveButton}>
                  <DefaultButton onClick={modSave} disabled={loadingModUpdate}>
                    Save
                    {loadingModUpdate && (
                      <CircularProgress
                        color="inherit"
                        size={20}
                        className={styles.loadingSpinner}
                      />
                    )}
                  </DefaultButton>
                </div>
              </Card>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

interface ModifiedLearningDriver {
  label: string;
  value: number;
  questionInfo?: string;
  generalHappinessQuestion?: boolean;
  learningContentAssigned?: boolean;
  assignedLearningContent?: number;
}

interface ModifiedCompany {
  label: string;
  value: number;
  imageUrl?: string;
  assignedLearningContent?: number;
  learningContentAssigned?: boolean;
}

interface ModifiedTag {
  label: string;
  value: number;
  imageUrl?: string;
  orderId?: number;
  assignedLearningContent?: number;
  learningContentAssigned?: boolean;
}

interface ModifiedLearningQuestion {
  id?: number;
  questionText?: string;
  questionInfo?: string;
  generalHappinessQuestion?: string;
  learningContentAssigned?: string;
  assignedLearningContent?: { learningId: number };
}

type LearningContentResponse = {
  success: boolean;
};

export default EmployerLearningConfig;
