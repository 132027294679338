import { getAllLearningByFilterCriteria, getAllLearningByTagId } from '@api/learningContent';
import {
  LearningSearchLogPayload,
  LearningSearchLogResponse,
  learningContentApiHandler,
} from '@api/learningContentApiHandler';
import { getAllLearningTagsWithContent } from '@api/learningTags';
import LoadingPage from '@components/LoadingPage/LoadingPage';
import { LearningTypes } from '@enums/LearningTypes';
import { smoothScrollTo } from '@helpers/general.helpers';
import { ApiResponse, LearningTagInterface, PaginationInterface } from '@interfaces/index';
import { LearningContentAutocomplete } from '@shared/molecules';
import {
  FilteredArticlesSection,
  TagsCarouselSection,
  WellbeingPillarsSection,
} from '@shared/organisms';
import { CoreLayout } from '@shared/templates';
import { useMutation } from '@tanstack/react-query';
import React, { Suspense, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from './index.module.scss';

export const Learnings: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const typeParam = urlParams.get('type');

  useEffect(() => {
    if (!urlParams.has('type')) {
      urlParams.append('type', 'learningHome');
      history.push({ search: urlParams.toString() });
    }
  }, [history, location]);

  const [learningCategoryTags, setLearningCategoryTags] = useState<LearningTagInterface[] | []>([]);
  const [learningCategoryTagsModified, setLearningCategoryTagsModified] = useState<
    { id: number; innerImageUrl: string; title: string; displayInCarousel?: boolean }[] | []
  >([]);
  const [learningCategoryTagsLoading, setLearningCategoryTagsLoading] = useState<boolean>(true);

  const [latestContentLoading, setLatestContentLoading] = useState<boolean>(true);
  const [handleLoadMoreLoading, setHandleLoadMoreLoading] = useState<boolean>(false);
  const [latestLearnings, setLatestLearnings] = useState<
    { id: number; innerImageUrl: string; title: string }[] | []
  >([]);
  const [latestLearningPagination, setLatestLearningPagination] = useState<PaginationInterface>({
    page: 0,
    perPage: 0,
    pageDataCount: 0,
    totalDataCount: 0,
    totalPages: 0,
    hasNext: false,
    hasPrevious: false,
    remaining: 0,
  });

  const [learningsGroupByTagsLoading, setLearningsGroupedByTagsLoading] = useState<boolean>(true);
  const [learningsGroupByTags, setLearningsGroupedByTags] = useState<
    | {
        id: number;
        tag: string;
        displayInCarousel?: boolean;
        content:
          | { id: number; innerImageUrl: string; title: string; tagId?: number; tag?: string }[]
          | [];
      }[]
    | []
  >([]);

  const [singleTagContentLoading, setSingleTagContentLoading] = useState<boolean>(true);
  const [singleTagContent, setSingleTagContent] = useState<
    { id: number; innerImageUrl: string; title: string }[] | []
  >([]);
  const [questionContentLoading, setQuestionContentLoading] = useState<boolean>(false);
  const [questionContent, setQuestionContent] = useState<
    { id: number; innerImageUrl: string; title: string }[] | []
  >([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [learningContentOptions, setLearningContentOptions] = useState<
    { label: string; value: number; imageUrl: string; type: string | null }[] | []
  >([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);

  const categoryId = Number(urlParams.get('categoryId'));
  const categoryName = urlParams.get('categoryName');
  const questionId = Number(urlParams.get('questionId'));
  const questionName = urlParams.get('questionName');
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);

  const { mutateAsync: searchLearningContents, isPending } = useMutation({
    mutationFn: learningContentApiHandler.searchLearningContent,
  });

  const useLogLearningSearchQuery = () =>
    useMutation<ApiResponse<LearningSearchLogResponse>, Error, Partial<LearningSearchLogPayload>>({
      mutationFn: (payload) => learningContentApiHandler.logLearningSearchQuery(payload),
    });

  const { mutate: logMutate } = useLogLearningSearchQuery();

  useEffect(() => {
    getTagCategories();
    getPaginatedLearningContentByTag();
  }, []);

  useEffect(() => {
    if (learningCategoryTags?.length > 0) {
      fetchAllLearningByTags();
    }
  }, [learningCategoryTags]);

  useEffect(() => {
    if (questionId && typeParam === 'questionContent') {
      getLearningContentFromQueId(1, [questionId], []);
    }
  }, [questionId, typeParam]);

  const getTagCategories = async () => {
    try {
      setLearningCategoryTagsLoading(true);
      const response = await getAllLearningTagsWithContent();

      const moddedTags = await fetchContentForTags(response);
      const filterResponseByLearningContent = () =>
        response.filter((resItem) =>
          moddedTags.some((tagItem) => tagItem.id === resItem.id && tagItem.content.length > 0),
        );

      const moddedTagsWithContent = filterResponseByLearningContent();

      setLearningCategoryTags(moddedTagsWithContent || []);

      const modifiedTags = moddedTagsWithContent.map((dt) => ({
        id: dt.id,
        innerImageUrl: dt.thumbnailImageUrl,
        title: dt.tag,
        displayInCarousel: dt.displayInCarousel,
      }));

      setLearningCategoryTagsModified(modifiedTags);
    } catch (error) {
      setLearningCategoryTags([]);
    } finally {
      setLearningCategoryTagsLoading(false);
    }
  };

  const getPaginatedLearningContentByTag = async (
    page = 1,
    questionIds: number[] = [],
    tagIds: number[] = [],
  ) => {
    try {
      setLatestContentLoading(true);
      const response = await getAllLearningByFilterCriteria({
        order: 'DESC',
        orderBy: 'contentCreatedAt',
        page,
        perPage: 30,
        questionIds,
        tagIds,
      });

      const modifiedData =
        response?.data?.map((dt) => ({
          id: dt.id,
          innerImageUrl: dt.imageUrl,
          title: dt.title,
        })) || [];

      if (page > 1) {
        setLatestLearnings((prevLearnings) => [...prevLearnings, ...modifiedData]);
      } else {
        setLatestLearnings(modifiedData);
      }

      setLatestLearningPagination(response?.pagination);
    } catch (error) {
      setLatestLearnings([]);
    } finally {
      setLatestContentLoading(false);
    }
  };

  const fetchContentForTags = async (tags: LearningTagInterface[]) => {
    const finalOutput = await Promise.all(
      tags.map(async (tag) => {
        const contentData = await getAllLearningByTagId({ tagId: tag.id });

        const modifiedData = contentData?.map((dt) => ({
          id: dt.id,
          innerImageUrl: dt.imageUrl,
          title: dt.title,
          tagId: tag.id,
          tag: tag.tag,
        }));

        return {
          id: tag.id,
          tag: tag.tag,
          displayInCarousel: tag.displayInCarousel,
          content: modifiedData,
        };
      }),
    );

    return finalOutput;
  };

  const fetchAllLearningByTags = async () => {
    try {
      setLearningsGroupedByTagsLoading(true);

      const output = await fetchContentForTags(learningCategoryTags);
      setLearningsGroupedByTags(output);
    } finally {
      setLearningsGroupedByTagsLoading(false);
    }
  };

  const fetchAllLearningByTagId = async (tagId: number) => {
    try {
      setSingleTagContentLoading(true);

      const contentData = await getAllLearningByTagId({ tagId });

      const modifiedData = contentData?.map((dt) => ({
        id: dt.id,
        innerImageUrl: dt.imageUrl,
        title: dt.title,
      }));
      setSingleTagContent(modifiedData);
    } finally {
      setSingleTagContentLoading(false);
    }
  };

  useEffect(() => {
    if (categoryId) {
      fetchAllLearningByTagId(categoryId);
    }
  }, [categoryId]);

  const handleClickNavigate = (typeName: string) => {
    const newUrlParams = new URLSearchParams(window.location.search);
    newUrlParams.set('type', typeName);

    history.push({ search: newUrlParams.toString() });
  };

  const handleLoadMore = () => {
    setHandleLoadMoreLoading(true);
    const nextPage = currentPage + 1;

    setTimeout(() => {
      setHandleLoadMoreLoading(false);
      getPaginatedLearningContentByTag(nextPage, [], []);
      setCurrentPage(nextPage);
    }, 2000);
  };

  const getLearningContentFromQueId = async (
    page = 1,
    questionIds: number[] = [],
    tagIds: number[] = [],
  ) => {
    try {
      setQuestionContentLoading(true);
      const response = await getAllLearningByFilterCriteria({
        order: 'DESC',
        orderBy: 'contentCreatedAt',
        page,
        perPage: 1000,
        questionIds,
        tagIds,
      });

      const modifiedData =
        response?.data?.map((dt) => ({
          id: dt.id,
          innerImageUrl: dt.imageUrl,
          title: dt.title,
        })) || [];

      if (page > 1) {
        setQuestionContent((prevLearnings) => [...prevLearnings, ...modifiedData]);
      } else {
        setQuestionContent(modifiedData);
      }
    } catch (error) {
      setQuestionContent([]);
    } finally {
      setQuestionContentLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery?.length >= 4) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      const newTimeout = setTimeout(() => {
        handleSearch();
      }, 1000);

      setSearchTimeout(newTimeout);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
  }, []);

  const handleSearch = async () => {
    await getLearnings(searchQuery);
  };

  const getLearnings = async (title: string) => {
    setSearchLoading(true);
    const res = await searchLearningContents(title);

    if (res.success) {
      const modRes = res.responseObject?.map(
        (item: { title: string; id: number; imageUrl: string; type: string }) => ({
          label: item.title,
          value: item.id,
          imageUrl: item.imageUrl,
          type: item.type,
        }),
      );

      setLearningContentOptions(modRes);
    } else {
      setLearningContentOptions([]);
    }

    setTimeout(() => {
      setSearchLoading(false);
    }, 1000);
  };

  const handleSearchQueryChange = (query: string) => {
    setSearchQuery(query);
    if (query.length === 0) {
      setLearningContentOptions([]);
    }

    setSearchLoading(true);

    setTimeout(() => {
      setSearchLoading(false);
    }, 1000);
  };

  const handleRedirectOnSearchClick = (id: number, query: string, type: string | null) => {
    switch (type) {
      case LearningTypes.CONTENT:
        history.push(`/post/${id}`);
        break;

      case LearningTypes.CATEGORY:
        history.push(
          `/posts?type=singleCategoryContent&categoryId=${id}&categoryName=${encodeURIComponent(
            query,
          )}`,
        );
        break;

      default:
        toast.error('Something went wrong');
    }

    const payload: Partial<LearningSearchLogPayload> = {
      searchQuery: query,
      contentId: type === LearningTypes.CONTENT ? id : null,
      tagId: type === LearningTypes.CATEGORY ? id : null,
    };

    logMutate(payload);
  };

  return (
    <CoreLayout title="Learnings" navigationType="default">
      <div className="container">
        <div className={styles.title}>
          <h1>Learn</h1>

          <div className={styles.searchContainer}>
            <LearningContentAutocomplete
              learningContentOptions={learningContentOptions}
              searchQuery={searchQuery}
              searchLoading={searchLoading || isPending}
              onSearchQueryChange={handleSearchQueryChange}
              onSelectionChange={handleRedirectOnSearchClick}
            />
          </div>

          {(() => {
            switch (typeParam) {
              case 'learningHome':
                smoothScrollTo(0, 150);

                return (
                  <Suspense fallback={<LoadingPage />}>
                    <WellbeingPillarsSection />
                    <TagsCarouselSection
                      title="Categories"
                      data={learningCategoryTagsModified
                        .filter((dt) => dt.displayInCarousel)
                        .slice(0, 12)}
                      loading={learningCategoryTagsLoading}
                      carouselType="categories"
                      onClickNavigation={() => handleClickNavigate('categories')}
                    />

                    <TagsCarouselSection
                      title="Latest content"
                      data={latestLearnings.slice(0, 12)}
                      loading={latestContentLoading}
                      carouselType="posts"
                      onClickNavigation={() => handleClickNavigate('latestContents')}
                    />

                    {learningsGroupByTags
                      .filter((dt) => dt?.content?.length > 0)
                      .map((tg, index) => (
                        <TagsCarouselSection
                          key={index}
                          tagId={tg.id}
                          title={tg.tag}
                          data={tg?.content?.slice(0, 12)}
                          loading={learningsGroupByTagsLoading}
                          carouselType="categoryPosts"
                          onClickNavigation={() => handleClickNavigate('latestContents')}
                        />
                      ))}
                  </Suspense>
                );

              case 'categories':
                smoothScrollTo(0, 150);

                return (
                  <Suspense fallback={<LoadingPage />}>
                    <FilteredArticlesSection
                      loading={learningCategoryTagsLoading}
                      title="All Categories"
                      data={learningCategoryTagsModified}
                      onClickNavigateBack={() => handleClickNavigate('learningHome')}
                      sectionType="categories"
                    />
                  </Suspense>
                );

              case 'latestContents':
                smoothScrollTo(0, 150);

                return (
                  <Suspense fallback={<LoadingPage />}>
                    <FilteredArticlesSection
                      loading={latestContentLoading}
                      title="Latest content"
                      data={latestLearnings}
                      onClickNavigateBack={() => handleClickNavigate('learningHome')}
                      sectionType="posts"
                      paginationProps={latestLearningPagination}
                      handleLoadMore={handleLoadMore}
                      handleLoadMoreLoading={handleLoadMoreLoading}
                    />
                  </Suspense>
                );

              case 'singleCategoryContent': {
                if (categoryId && categoryName) {
                  smoothScrollTo(0, 150);

                  return (
                    <Suspense fallback={<LoadingPage />}>
                      <FilteredArticlesSection
                        loading={singleTagContentLoading}
                        title={categoryName}
                        data={singleTagContent}
                        onClickNavigateBack={() => handleClickNavigate('learningHome')}
                        sectionType="posts"
                      />
                    </Suspense>
                  );
                }

                return null;
              }

              case 'singleCategoryContentTags': {
                if (categoryId && categoryName) {
                  smoothScrollTo(0, 150);

                  return (
                    <Suspense fallback={<LoadingPage />}>
                      <FilteredArticlesSection
                        title={categoryName}
                        data={singleTagContent}
                        onClickNavigateBack={() => handleClickNavigate('categories')}
                        sectionType="posts"
                      />
                    </Suspense>
                  );
                }

                return null;
              }

              case 'questionContent': {
                if (questionId && questionName) {
                  smoothScrollTo(0, 150);

                  return (
                    <Suspense fallback={<LoadingPage />}>
                      <FilteredArticlesSection
                        loading={questionContentLoading}
                        title={`${questionName} wellbeing`}
                        data={questionContent}
                        onClickNavigateBack={() => handleClickNavigate('learningHome')}
                        sectionType="posts"
                      />
                    </Suspense>
                  );
                }

                return null;
              }

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </CoreLayout>
  );
};
