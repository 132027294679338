import { TalkToAdvisorRequestsResponseData } from '@api/advisorApiHandler';
import DataTable from '@components/Atoms/DataTable';
import DefaultButton from '@components/Atoms/DefaultButton';
import { isNullValidation } from '@helpers/validation.helper';
import { useGetTalkToAdvisorRequests } from '@hooks/api/useAdvisor';
import useInput, { getInputValue } from '@hooks/useInput';
import MainLayout from '@layouts/MainLayout';
import { formatDateToDateMonthYear, generateDateTime } from '@utils/date.util';
import moment from 'moment-timezone';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import styles from './index.module.scss';

const INITIAL_REQUESTS = {
  headers: [],
  data: [],
};

const EmployeeAdvisorRequests: FunctionComponent = () => {
  const defaultEndDate = moment().format('YYYY-MM-DD'); // Today's date
  const defaultStartDate = moment().subtract(1, 'week').format('YYYY-MM-DD'); // 1 week before today
  const [requests, setRequests] = useState<TalkToAdvisorRequestsResponseData>(INITIAL_REQUESTS);
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const handleRowClick = (row: any) => null;

  const {
    value: startDate,
    isValid: startDateIsValid,
    hasError: startDateHasError,
    valueChangeHandler: startDateChangeHandler,
    inputBlurHandler: startDateBlurHandler,
    reset: startDateReset,
  } = useInput(isNullValidation, getInputValue, defaultStartDate);

  const {
    value: endDate,
    isValid: endDateIsValid,
    hasError: endDateHasError,
    valueChangeHandler: endDateChangeHandler,
    inputBlurHandler: endDateBlurHandler,
    reset: endDateReset,
  } = useInput(isNullValidation, getInputValue, defaultEndDate);

  const { data, isLoading, isError, refetch } = useGetTalkToAdvisorRequests(
    startDate,
    endDate,
    isQueryEnabled,
  );

  useEffect(() => {
    if (data?.success) {
      setRequests(data?.responseObject || INITIAL_REQUESTS);
      setIsQueryEnabled(false);
    } else {
      setRequests(INITIAL_REQUESTS);
      setIsQueryEnabled(true);
    }
  }, [data, isLoading]);

  const submit = (e: React.FormEvent) => {
    e.preventDefault();
    refetch();
  };

  const tableColumns: ColumnDefinition<(typeof formattedTableData)[0]>[] = [
    {
      id: 'index',
      label: '',
    },
    ...requests.headers.map((col) => ({
      id: col.key as keyof (typeof requests.data)[0],
      label:
        col.label || col.key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()),
    })),
  ];

  const formattedTableData = requests.data.map((row, index) => ({
    index: index + 1,
    ...row,
    requestedDate: row.requestedDate ? formatDateToDateMonthYear(row.requestedDate) : '-',
    area: row.area || '-',
    jobLocation: row.jobLocation || '-',
    team: row.team || '-',
    jobTitle: row.jobTitle || '-',
    jobType: row.jobType || '-',
    focusAreas:
      row.focusAreas && row.focusAreas.length > 0
        ? Array.isArray(row.focusAreas)
          ? row.focusAreas.join(', ')
          : row.focusAreas
        : '-',
  }));

  const maxFocusAreas = Math.max(
    ...requests.data.map((row) => (Array.isArray(row.focusAreas) ? row.focusAreas.length : 0)),
  );

  const focusAreaKeys = Array.from({ length: maxFocusAreas }, (_, i) => `focusArea_${i + 1}`);

  const filteredHeaders = requests.headers.filter((col) => col.key !== 'focusAreas');

  const formattedCsvData = requests.data.map((row, index) => {
    const focusAreasArray = Array.isArray(row.focusAreas)
      ? row.focusAreas
      : row.focusAreas
      ? [row.focusAreas]
      : [];

    const focusAreaColumns = focusAreaKeys.reduce<Record<string, string>>((acc, key, i) => {
      acc[key] = focusAreasArray[i] || '';

      return acc;
    }, {});

    return {
      index: index + 1,
      ...row,
      requestedDate: row.requestedDate ? formatDateToDateMonthYear(row.requestedDate) : '-',
      area: row.area || '-',
      jobLocation: row.jobLocation || '-',
      team: row.team || '-',
      jobTitle: row.jobTitle || '-',
      jobType: row.jobType || '-',
      ...focusAreaColumns,
    };
  });

  const dynamicCsvHeaders = [
    ...filteredHeaders.map((col) => ({
      label:
        col.label || col.key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()),
      key: col.key,
    })),
    ...focusAreaKeys.map((key, i) => ({ label: `Focus Area ${i + 1}`, key })),
  ];

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    startDateChangeHandler(e);
    setIsFilterChanged(true);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    endDateChangeHandler(e);
    setIsFilterChanged(true);
  };

  return (
    <MainLayout title="Admin Portal" useEmployerNav isEmployerPage secondaryNavEnabled>
      <div className={styles.mainContainer}>
        <div className={styles.pageTitleContainer}>
          <h1 className={styles.pageTitle}>Talk to Advisor Requests</h1>
          <CSVLink
            data={formattedCsvData}
            headers={dynamicCsvHeaders}
            filename={`TalkToAdvisorRequests_${generateDateTime()}.csv`}
            className="exportBtn"
          >
            <DefaultButton
              color="primary"
              disabled={isLoading || isError || !formattedCsvData.length}
            >
              Export
            </DefaultButton>
          </CSVLink>
        </div>

        <div className={styles.filterRoot}>
          <form onSubmit={submit}>
            <div className={styles.sliceAndDiceSection}>
              <div className={styles.sliceAndDiceFlex}>
                <div>
                  <div>
                    <div className={styles.tooltipContainer}>
                      <p>Start Date</p>
                    </div>
                    <input
                      type="date"
                      className={`${styles.formTextInputStyles} ${
                        startDateHasError ? 'error' : ''
                      }`}
                      value={startDate}
                      onChange={handleStartDateChange}
                      onBlur={startDateBlurHandler}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <div className={styles.tooltipContainer}>
                      <p>End Date</p>
                    </div>
                    <input
                      type="date"
                      className={`${styles.formTextInputStyles} ${endDateHasError ? 'error' : ''}`}
                      value={endDate}
                      onChange={handleEndDateChange}
                      onBlur={endDateBlurHandler}
                      min={startDate}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <div className={styles.buttonGroup}>
                  <DefaultButton
                    disabled={!startDateIsValid || !endDateIsValid || !isFilterChanged}
                    type="submit"
                    color="primary"
                  >
                    Apply
                  </DefaultButton>

                  <DefaultButton
                    type="button"
                    onClick={async () => {
                      startDateReset();
                      endDateReset();
                      await refetch();
                      setIsFilterChanged(false);
                    }}
                    color="primary"
                    disabled={!isFilterChanged}
                  >
                    Clear Filter
                  </DefaultButton>
                </div>
              </div>
            </div>
          </form>
        </div>

        <DataTable
          data={formattedTableData}
          columns={tableColumns}
          onRowClick={handleRowClick}
          rowsPerPageOptions={[5, 10, 25]}
          defaultRowsPerPage={10}
          noDataFoundText="No talk to advisor requests found"
          loading={isLoading}
        />
      </div>
    </MainLayout>
  );
};

type ColumnDefinition<T> = {
  id: keyof T;
  label: string;
};

export default EmployeeAdvisorRequests;
