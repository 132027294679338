import { ApiResponse } from '@interfaces/index';
import createApiClient from './configs/httpHandler';
import { apiUrlConfig } from './configs/apiUrlConfig';

export const assessmentQuestionnaireApiHandler = {
  getQuestionsByQuestionnaire: async (
    questionnaireId: number,
  ): Promise<ApiResponse<QuestionnaireInterface>> =>
    createApiClient(apiUrlConfig.EXPRESS_BACKEND_V2).get(
      `/get-questions-by-questionnaire/${questionnaireId}`,
    ),

  createUserAssessment: async (
    payload: Partial<CreateUserAssessment>,
  ): Promise<ApiResponse<UserAssessmentResponse>> =>
    createApiClient(apiUrlConfig.EXPRESS_BACKEND_V2).post('/create-user-assessment', payload),

  getAllCompanyQuestionnaires: async (
    companyId: number,
  ): Promise<ApiResponse<CompanyQuestionnaireDataInterface[]>> =>
    createApiClient(apiUrlConfig.EXPRESS_BACKEND_V2).get(
      `/get-company-questionnaires/${companyId}`,
    ),

  getAllCompanyOnboardingQuestionnaires: async (): Promise<
    ApiResponse<CompanyQuestionnaireDataInterface[]>
  > =>
    createApiClient(apiUrlConfig.EXPRESS_BACKEND_V2).get(
      `/get-company-onboarding-questionnaires-for-user`,
    ),

  getQuestionnaireCategories: async (
    questionnaireId: number,
  ): Promise<ApiResponse<QuestionnaireCategoryInterface[]>> =>
    createApiClient(apiUrlConfig.EXPRESS_BACKEND_V2).get(
      `/get-questionnaire-categories/${questionnaireId}`,
    ),

  createTalkToAdvisor: async (
    payload: Partial<{ assessmentId: number; categoryIds: number[] }>,
  ): Promise<ApiResponse<CreateTalkToAdvisorResponseInterface[]>> =>
    createApiClient(apiUrlConfig.EXPRESS_BACKEND_V2).post('/create-talk-to-advisor', payload),

  createReflection: async (
    payload: Partial<{ assessmentId: number | null; reflection: string }>,
  ): Promise<ApiResponse<CreateReflectionResponseInterface>> =>
    createApiClient(apiUrlConfig.EXPRESS_BACKEND_V2).post('/create-reflection', payload),

  saveEWSSpeakUp: async (
    payload: Partial<EWSSpeakUpPayload>,
  ): Promise<ApiResponse<EWSSpeakUpResponseInterface>> =>
    createApiClient(apiUrlConfig.EXPRESS_BACKEND_V2).post('/instant-help-speak-up', payload),
};

// Interface for the questionnaire response
export interface QuestionnaireInterface {
  id: number;
  name: string;
  shortDescription: string;
  description: string;
  version: string;
  type: string;
  default: boolean;
  createdAt: string;
  updatedAt: string;
  questionnairesV2Questions: QuestionInterface[];
}

export interface QuestionInterface {
  id: number;
  questionnaireId: number;
  categoryId: number;
  responseScaleId: number;
  questionText: string;
  orderId: number;
  required: boolean;
  icon: string | null;
  buttonText: string | null;
  description: string | null;
  isBrakeOff: boolean;
  createdAt: string;
  updatedAt: string;
  questionnairesV2QuestionResponseScales: QuestionResponseScale;
}

export interface QuestionResponseScale {
  id: number;
  scale: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  questionnairesV2QuestionResponseScaleOptions: QuestionScaleOption[];
}

export interface QuestionScaleOption {
  id: number;
  scaleId: number;
  optionText: string;
  score: number;
  orderId: number;
  createdAt: string;
  updatedAt: string;
}

// Interface for creating user assessment
export interface CreateUserAssessment {
  questionnaireId: number;
  userResponses: UserResponse[];
}

export interface UserResponse {
  questionId: number;
  selectedOptionId: number;
  score: number;
}

// Response after submitting assessment
export interface UserAssessmentResponse {
  userId: number;
  questionnaireId: number;
  totalScore: number;
  id: number;
  createdAt: string;
  updatedAt: string;
  ewsTriggered: boolean;
}

// Interface for the company questionnaire
export interface CompanyQuestionnaireDataInterface {
  id: number;
  questionnaireId: number;
  companyId: number;
  orderId: number;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  onboardOrderId?: number;
  isOnboard?: boolean;
  questionnaireV2: {
    id: number;
    name: string;
    shortDescription: string;
    description: string;
    version: string;
    type: string;
    default: boolean;
    createdAt: string;
    updatedAt: string;
  };
}

// Response questionnaire categories
export interface QuestionnaireCategoryInterface {
  id: number;
  questionnaireId: number;
  name: string;
  ewsEnabled: boolean;
  ewsLimit: number;
  createdAt: string;
  updatedAt: string;
}

// Response for create talk to advisor
export interface CreateTalkToAdvisorResponseInterface {
  id: number;
  userId: number;
  assessmentId: number;
  categoryId: number;
  createdAt: string;
  updatedAt: string;
}

// Response for create talk to advisor
export interface CreateReflectionResponseInterface {
  id: number;
  usersId: number;
  userAssessmentId: number;
  assessmentId: number | null;
  reflection: string;
  createdAt: string;
  updatedAt: string;
}

// EWS Speak Up Payload
export interface EWSSpeakUpPayload {
  assessmentId: number | null;
  message: string;
  shareMyIdentity: boolean;
  questionnaireId: number;
}

// EWS Speak Up Response
export interface EWSSpeakUpResponseInterface {
  userId: number;
  userAssessmentId: number;
  questionnaireV2Id: number;
  message: string;
  shareMyIdentity: boolean;
  responded: boolean;
  archived: boolean;
  id: number;
  createdAt: string; // ISO date string
  updatedAt: string; // ISO date string
}
