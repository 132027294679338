import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { MotionProps, motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';

type ImageShape = 'circle' | 'rounded' | 'rectangle';

interface ImgProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, keyof MotionProps> {
  src: string;
  alt: string;
  className?: string;
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  animation?: MotionProps;
  shape?: ImageShape;
  skeletonHeight?: number | string;
  skeletonWidth?: number | string;
  style?: React.CSSProperties;
}

export const MotionImage: React.FC<ImgProps> = ({
  src,
  alt,
  className,
  animation,
  onError,
  shape = 'rectangle',
  skeletonHeight,
  skeletonWidth,
  style,
  ...rest
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
  }, [src]);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setIsLoading(false);
    img.onerror = () => setIsVisible(false);
  }, [src]);

  const handleError = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      setIsVisible(false);
      if (onError) {
        onError(event);
      }
    },
    [onError],
  );

  const handleLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  if (!isVisible) return null;

  const getSkeletonVariant = () => {
    switch (shape) {
      case 'circle':
        return 'circular';
      case 'rounded':
        return 'rounded';
      case 'rectangle':
      default:
        return 'rectangular';
    }
  };

  const width = skeletonWidth || rest.width || '100%';
  const height = skeletonHeight || rest.height || '100%';

  return (
    <div className={styles.imageContainer}>
      {isLoading && (
        <Skeleton
          variant={getSkeletonVariant()}
          width={width}
          height={height}
          className={classNames(styles.skeleton, {
            [styles.circleSkeleton]: shape === 'circle',
            [styles.roundedSkeleton]: shape === 'rounded',
            [styles.rectangleSkeleton]: shape === 'rectangle',
          })}
        />
      )}
      <motion.img
        src={src}
        alt={alt}
        className={classNames(styles.image, className, {
          [styles.loading]: isLoading,
          [styles.circleImage]: shape === 'circle',
          [styles.roundedImage]: shape === 'rounded',
        })}
        onError={handleError}
        onLoad={handleLoad}
        style={style}
        {...(animation as MotionProps)}
        {...rest}
      />
    </div>
  );
};

MotionImage.defaultProps = {
  className: styles.image,
  onError: (e: React.SyntheticEvent<HTMLImageElement, Event>) => null,
  animation: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  },
  shape: 'rectangle',
  skeletonHeight: '100%',
  skeletonWidth: '100%',
  style: {},
};
