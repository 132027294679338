import React, { useContext, useEffect, useState } from 'react';
import StepWizardHeader from '@components/StepWizardHeader';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { getQuestionnairesNames } from '@api/questionnaires';
import { SET_CURRENT_TAB, SET_GRAPH_DATA, useGlobalState } from '@context/GlobalStore';
import { useHistory } from 'react-router';
import { logoutUser } from '@helpers/auth.helper';
import MobileToggler from './components/MobileToggler';
import styles from './index.module.scss';

export const SecondaryNavigation: React.FunctionComponent = () => {
  const { isMobile } = useWindowDimensions();
  const { activeSubNav, toggleSubNav } = useContext(SecondaryNavigationContext);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [subNavs, setSubNavs] = useState([]) as any;
  const [_, dispatch] = useGlobalState();

  useEffect(() => {
    const getQuestionnaires = async () => {
      const data = await getQuestionnairesNames();

      if (data === 401) {
        logoutUser();
        history.push('/login?action=logout');
      }

      setSubNavs(data);
    };

    getQuestionnaires();
  }, [setSubNavs]);

  useEffect(() => {
    setIsOpen(!isMobile);
  }, [isMobile]);

  const activeMenuName =
    (subNavs.length > 0 &&
      subNavs.find((route: { id: number; name: string }) => route.name === activeSubNav.name)) ||
    subNavs[0];

  const handleClick = (item: any) => {
    if (item) {
      toggleSubNav(item);
      dispatch({ type: SET_CURRENT_TAB, payload: item });
      dispatch({ type: SET_GRAPH_DATA, payload: { assesmentId: 0, date: '' } });
      if (isMobile) {
        setIsOpen(!isOpen);
      }
    }
  };

  return (
    <div className={styles.hsSubNavigation}>
      {activeMenuName && isMobile && isOpen !== null && (
        <MobileToggler openHandler={setIsOpen} isOpen={isOpen} name={activeMenuName?.name} />
      )}
      {isOpen && (
        <ul>
          {subNavs.length > 0 &&
            subNavs.map((item: { id: number; name: string }, index: number) => (
              <li
                key={index}
                {...(item.name === activeSubNav.name && {
                  className: styles.active,
                })}
              >
                <a onClick={() => handleClick(item)}>{item.name}</a>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
