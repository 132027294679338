import React from 'react';
import { Slide, ToastContainer, ToastOptions, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Toast types
export type ToastType = 'success' | 'error' | 'info' | 'warning';

// Default toast options
const defaultOptions: ToastOptions = {
  position: 'top-right',
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'light',
  transition: Slide,
  role: 'alert',
};

// Toast function
export const showToast = (message: string, options?: ToastOptions, type: ToastType = 'info') => {
  const toastOptions = { ...defaultOptions, ...options };
  switch (type) {
    case 'success':
      toast.success(message, toastOptions);
      break;
    case 'error':
      toast.error(message, toastOptions);
      break;
    case 'info':
      toast.info(message, toastOptions);
      break;
    case 'warning':
      toast.warning(message, toastOptions);
      break;
    default:
      toast(message, toastOptions);
  }
};

// Toast provider component
export const ToastProvider: React.FC = () => (
  <ToastContainer hideProgressBar limit={3} style={{ zIndex: 99999999 }} />
);
