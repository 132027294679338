import classNames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useMemo } from 'react';
import styles from './index.module.scss';

interface SanitizedTextProps {
  content?: string | null;
  as?: keyof JSX.IntrinsicElements;
  className?: string;
}

export const SanitizedText: React.FC<SanitizedTextProps> = ({
  content = '',
  as: Tag = 'div',
  className,
}) => {
  const sanitizedContent = useMemo(
    () =>
      DOMPurify.sanitize(content || '', {
        ALLOWED_TAGS: [
          'b',
          'i',
          'em',
          'strong',
          'a',
          'p',
          'h1',
          'h2',
          'h3',
          'h4',
          'h5',
          'h6',
          'span',
          'ul',
          'ol',
          'li',
          'br',
        ],
        ALLOWED_ATTR: ['href', 'target', 'rel', 'class', 'style'],
        KEEP_CONTENT: true,
      }).replace(/\n/g, ''),
    [content],
  );

  return <Tag className={className} dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
};

SanitizedText.defaultProps = {
  content: '',
  as: 'div',
  className: classNames(styles.textStyles),
};
