import { ApiResponse } from '@interfaces/index';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiUrlConfig } from './configs/apiUrlConfig';
import createApiClient from './configs/httpHandler';

export const userStatusApiHandler = {
  getUserStatus: async (): Promise<ApiResponse<UserStatusInterface>> =>
    createApiClient(apiUrlConfig.EXPRESS_BACKEND_V2).get('/get-user-status'),

  updateUserStatus: async (
    payload: Partial<UpdateUserStatusPayload>,
  ): Promise<ApiResponse<UserStatusInterface>> =>
    createApiClient(apiUrlConfig.EXPRESS_BACKEND_V2).patch('/update-user-status', payload),
};

export const useUserStatus = () =>
  useQuery<ApiResponse<UserStatusInterface>>({
    queryKey: ['userStatus'],
    queryFn: userStatusApiHandler.getUserStatus,
  });

export const useUpdateUserStatus = () => {
  const queryClient = useQueryClient();

  return useMutation<ApiResponse<UserStatusInterface>, Error, Partial<UpdateUserStatusPayload>>({
    mutationFn: userStatusApiHandler.updateUserStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userStatus'] }); // Refetch user status after update
    },
  });
};

// Interface for user status response
export interface UserStatusInterface {
  id: number;
  userId: number;
  isRegistered: boolean | undefined;
  isJoined: boolean;
  isEligibleForSpeakupAlerts: boolean | undefined;
  isFirstShortStarCompleted: boolean | undefined;
  createdAt: string;
  updatedAt: string;
  isOnboardCompleted: boolean | undefined;
}

// Interface for updating user status
export interface UpdateUserStatusPayload {
  isRegistered?: boolean;
  isJoined?: boolean | null;
  isEligibleForSpeakUpAlerts?: boolean | null;
  isOnboardCompleted?: boolean | null;
  isFirstShortStarCompleted?: boolean | null;
}
