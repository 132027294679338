import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React from 'react';
import styles from './index.module.scss';

interface ColumnDefinition<T> {
  id: keyof T;
  label: string;
  render?: (value: any, row: T) => React.ReactNode;
}

interface DataTableProps<T> {
  data: T[];
  columns: ColumnDefinition<T>[];
  onRowClick: (row: T) => void;
  rowsPerPageOptions: number[];
  defaultRowsPerPage: number;
  noDataFoundText?: string;
  loading?: boolean;
}

const DataTable = <T,>({
  data,
  columns,
  onRowClick,
  rowsPerPageOptions = [5, 10, 25],
  defaultRowsPerPage = 5,
  noDataFoundText = 'No requests found',
  loading = false,
}: DataTableProps<T>) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      {loading ? (
        <div className={styles.tableSkeleton}>
          {Array.from({ length: 15 }).map((_, index) => (
            <Skeleton variant="text" width="100%" height={30} key={index} />
          ))}
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table className={styles.tableRoot}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id as string} className={styles.headerLabelCell}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedData.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  onClick={() => onRowClick(row)}
                  style={{ cursor: 'pointer' }}
                >
                  {columns.map((column) => (
                    <TableCell key={column.id as string}>
                      {column.render
                        ? column.render(row[column.id as keyof T], row)
                        : String(row[column.id as keyof T])}
                    </TableCell>
                  ))}
                </TableRow>
              ))}

              {displayedData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={columns.length} className={styles.noDataCell}>
                    <div className={styles.noDataSection}>
                      <p>{noDataFoundText}</p>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {displayedData?.length > 0 && (
            <TablePagination
              component="div"
              count={data.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              labelRowsPerPage="Per page:"
            />
          )}
        </TableContainer>
      )}
    </>
  );
};

DataTable.defaultProps = {
  noDataFoundText: 'No requests found',
  loading: false,
};

export default DataTable;
