import styles from '@components/LoadingPage/index.module.scss';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { LoadingDots } from '@shared/atoms';
import React from 'react';

const LoadingPage: React.FunctionComponent = () => {
  const { isDesktop } = useWindowDimensions();

  return (
    <div className={styles.hsRoot}>
      <div className="initialLoadingContainer">
        <LoadingDots size={isDesktop ? 24 : 20} isHapstarLoading dotCount={3} />
      </div>
    </div>
  );
};

export default LoadingPage;
