import useWindowDimensions from '@hooks/useWindowDimensions';
import { Skeleton } from '@mui/material';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { LearningArticleCard } from '../LearningArticleCard/LearningArticleCard';
import styles from './index.module.scss';

export const LearningCarousel: React.FunctionComponent<LearningCarouselProps> = ({
  loading,
  data,
  carouselType,
  navigate,
  tagDetails,
}) => {
  const { isDesktop, isTablet } = useWindowDimensions();
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 4,
      partialVisibilityGutter: 20,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
  };

  return (
    <div>
      <div className={styles.carouselContainer}>
        <Carousel
          additionalTransfrom={2}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className={styles['gd-carousel']}
          containerClass=""
          ssr
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass={styles.carouselItem}
          keyBoardControl
          minimumTouchDrag={30}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside
          renderDotsOutside={false}
          responsive={responsive}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
          partialVisible
        >
          {loading
            ? Array.from({ length: isDesktop ? 5 : isTablet ? 2 : 1 }).map((_, index) => (
                <Skeleton
                  variant="circular"
                  width="100%"
                  height={200}
                  key={index}
                  className={styles.learningItemLoading}
                />
              ))
            : data?.map((dt, index) => (
                <LearningArticleCard
                  key={index}
                  data={dt}
                  navigate={navigate}
                  sectionType={carouselType}
                  tagDetails={{ tagId: dt?.tagId, title: dt?.tag }}
                  navigateTo="newPosts"
                />
              ))}
        </Carousel>
      </div>
    </div>
  );
};

LearningCarousel.defaultProps = {
  data: [],
  loading: false,
  tagDetails: { tagId: -1, title: '' },
};

interface LearningCarouselProps {
  loading?: boolean;
  data?: {
    id: number;
    innerImageUrl: string;
    title: string;
    tagId?: number;
    tag?: string | undefined;
  }[];
  carouselType: string;
  navigate: (id: number, title: string) => void;
  tagDetails?: { tagId: number | undefined; title: string };
}
