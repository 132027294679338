import { MotionButton } from '@shared/atoms/MotionButton/MotionButton';
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from './index.module.scss';

interface QuestionNavigationProps {
  onPrevious: () => void;
  onNext: () => void;
  showPrevious: boolean;
  className?: string;
}

export const QuestionNavigation: React.FC<QuestionNavigationProps> = ({
  onPrevious,
  onNext,
  showPrevious,
  className,
}) => (
  <div className={className}>
    {showPrevious && (
      <MotionButton onClick={onPrevious} className={styles.backBtn}>
        <ArrowBackIcon />
      </MotionButton>
    )}
    {/* <MotionButton onClick={onNext}>Continue</MotionButton> */}
  </div>
);

QuestionNavigation.defaultProps = {
  className: styles.navigationButtons,
};
