import {
  CompanyQuestionnaireDataInterface,
  QuestionnaireInterface,
  assessmentQuestionnaireApiHandler,
} from '@api/assessmentQuestionnaireApiHandler';
import { UpdateUserStatusPayload } from '@api/userStatusApiHandler';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { ApiResponse } from '@interfaces/index';
import { showToast } from '@shared/atoms';
import { SingleQuestionnaire } from '@shared/organisms';
import { CoreLayout } from '@shared/templates';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { updateUserStatusData } from 'store/Actions/UserStatusAction';
import { setChosenQuestionnaire } from 'store/Slices/QuestionnaireSliceV2';

export const OnboardingAssessmentPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isDesktop } = useWindowDimensions();
  const company = localStorage.getItem('company');
  const parsedCompany = JSON.parse(company || '{}');
  const { chosenQuestionnaire } = useAppSelector((state) => state.questionnaireV2 || {});
  const [currentIndex, setCurrentIndex] = useState(0);

  const fetchOnboardingQuestionnaires = async (): Promise<
    ApiResponse<CompanyQuestionnaireDataInterface[]>
  > => assessmentQuestionnaireApiHandler.getAllCompanyOnboardingQuestionnaires();

  const fetchQuestionsByQuestionnaire = async (): Promise<
    ApiResponse<QuestionnaireInterface> | undefined
  > => {
    if (!chosenQuestionnaire || !chosenQuestionnaire.questionnaireId) {
      showToast('Please select a questionnaire from the list', undefined, 'error');

      return undefined;
    }

    return assessmentQuestionnaireApiHandler.getQuestionsByQuestionnaire(
      chosenQuestionnaire.questionnaireId,
    );
  };

  const {
    data: questionnairesData,
    isLoading: questionnairesLoading,
    error: questionnairesError,
  } = useQuery({
    queryKey: ['getAllCompanyOnboardingQuestionnaires', parsedCompany.id],
    queryFn: fetchOnboardingQuestionnaires,
    enabled: !!parsedCompany?.id,
  });

  const {
    data: questionsData,
    isLoading: questionsLoading,
    error: questionsError,
  } = useQuery({
    queryKey: ['getOnboardingQuestionsByQuestionnaire', chosenQuestionnaire?.questionnaireId],
    queryFn: fetchQuestionsByQuestionnaire,
    enabled: !!(parsedCompany?.id && chosenQuestionnaire?.questionnaireId),
  });

  const questionnaires = questionnairesData?.success ? questionnairesData?.responseObject : [];

  useEffect(() => {
    if (questionnaires.length > 0) {
      dispatch(setChosenQuestionnaire(questionnaires[currentIndex]));
    }
  }, [questionnaires, currentIndex, dispatch]);

  const handleNextQuestionnaire = () => {
    const totalQuestionnaires = questionnaires?.length;
    const isLastQuestionnaire = currentIndex === totalQuestionnaires - 1;

    setCurrentIndex((prevIndex) => (prevIndex + 1) % questionnaires.length);

    const statusData: UpdateUserStatusPayload = {
      isRegistered: true,
      isJoined: true,
      isEligibleForSpeakUpAlerts: null,
      isOnboardCompleted: true,
      isFirstShortStarCompleted: null,
    };

    if (isLastQuestionnaire) {
      showToast(
        'Great job! You have completed the onboarding assessment. Thank you for your time and effort.',
        {
          autoClose: 100,
          onClose: async () => {
            await dispatch(updateUserStatusData(statusData));
            window.location.href = '/';
          },
        },
        'success',
      );
    }
  };

  return (
    <CoreLayout title="Onboarding Assessment Page" navigationType="default">
      <SingleQuestionnaire
        companyQuestionnaires={questionnaires}
        questionsData={questionsData}
        questionnairesLoading={questionnairesLoading}
        questionnairesError={questionnairesError}
        questionsLoading={questionsLoading}
        questionsError={questionsError}
        isOnboarding
        onComplete={handleNextQuestionnaire}
      />
    </CoreLayout>
  );
};
