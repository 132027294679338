import { assessmentQuestionnaireApiHandler } from '@api/assessmentQuestionnaireApiHandler';
import AwesomeOutlined from '@assets/images/starSteps/awesome-outlined.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import { isEmptyValidation } from '@helpers/validation.helper';
import { useAppSelector } from '@hooks/useAppSelector';
import useInput, { getInputValue } from '@hooks/useInput';
import { CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import { showToast } from '@shared/atoms';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import styles from './index.module.scss';

export const Reflection = ({
  handleStep,
  helpAvailability,
  handleGetHelp,
}: ReflectionSectionProps) => {
  const wizardData = useAppSelector((state: RootState) => state.shortStarWizard);
  const {
    value: reflectionText,
    isValid: reflectionTextIsValid,
    hasError: reflectionTextHasError,
    valueChangeHandler: reflectionTextChangeHandler,
    inputBlurHandler: reflectionTextBlurHandler,
    reset: reflectionTextReset,
  } = useInput(isEmptyValidation, getInputValue, '');
  const { createdAssessmentDetails } = useAppSelector((state) => state.questionnaireV2 || {});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const useSubmitReflection = () =>
    useMutation({
      mutationFn: ({
        assessmentId,
        reflection,
      }: {
        assessmentId: number | null;
        reflection: string;
      }) => {
        const payload = {
          assessmentId,
          reflection,
        };

        return assessmentQuestionnaireApiHandler.createReflection(payload);
      },
    });

  const submitReflectionMutation = useSubmitReflection();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (reflectionTextIsValid) {
      setIsSubmitting(true);
      submitReflectionMutation.mutate(
        {
          assessmentId: createdAssessmentDetails?.id || null,
          reflection: reflectionText,
        },
        {
          onSuccess: (response) => {
            if (response?.success) {
              showToast(
                'Great job! Your reflection has been saved and will be shared with your organisation.',
                {
                  autoClose: 800,
                  onClose: async () => {
                    await handleStep();
                  },
                },
                'success',
              );
            } else {
              toast.error('Error submitting reflection. Try again later');
            }
          },
          onError: (error: any) => {
            toast.error('Error submitting reflection');
          },
          onSettled: () => {
            setTimeout(() => {
              setIsSubmitting(false);
            }, 1000);
          },
        },
      );
    } else {
      toast.error('Please enter your reflection');
    }
  };

  return (
    <>
      <div className={styles.reflectionContainer}>
        <h3 className={styles.considerText}>Write it down</h3>
        <Img src={AwesomeOutlined} alt="AwesomeOutlined" className={styles.awesomeOutlined} />
        <p className={styles.description}>
          Just writing down your thoughts or feelings can significantly improve your wellbeing,
          offering a sense of release and clarity. This simple act of expression helps organize your
          thoughts, making it easier to navigate challenges and find peace of mind.
        </p>
        <div className={styles.inputContainer}>
          <TextField
            id="outlined-multiline-static"
            label="Your reflection"
            multiline
            rows={4}
            fullWidth
            onChange={reflectionTextChangeHandler}
            onBlur={reflectionTextBlurHandler}
          />
          <p className={styles.hsCharCounter}>{reflectionText?.length || 0}/2000 characters</p>
        </div>
        <div className={styles.continueBtn}>
          <DefaultButton
            type="button"
            onClick={handleSubmit}
            variant="secondary"
            className={styles.hsPrimaryButton}
            disabled={
              reflectionTextHasError ||
              reflectionText?.length === 0 ||
              !reflectionText ||
              isSubmitting
            }
          >
            {isSubmitting ? (
              <>
                <CircularProgress size={20} color="inherit" /> &nbsp;Sending...
              </>
            ) : (
              'Continue'
            )}
          </DefaultButton>
        </div>

        {!isSubmitting && (
          <div
            className={styles.skipBtn}
            onClick={() => {
              handleStep();
            }}
          >
            skip
          </div>
        )}

        {/* {helpAvailability && (
          <>
            {' '}
            <p className={styles.helpText}>
              If you’re struggling a need support, help is one click away
            </p>
            <div className={styles.helpBtn}>
              <DefaultButton
                type="button"
                onClick={() => handleGetHelp(true)}
                className={styles.hsHelpButton}
              >
                GET URGENT HELP
              </DefaultButton>
            </div>
          </>
        )} */}
      </div>
    </>
  );
};

Reflection.defaultProps = {
  helpAvailability: false,
  handleGetHelp: () => null,
};

interface ReflectionSectionProps {
  handleStep: () => void;
  helpAvailability?: boolean;
  handleGetHelp?: (arg1: boolean) => void;
}
