import {
  CompanyQuestionnaireDataInterface,
  QuestionnaireInterface,
  UserAssessmentResponse,
} from '@api/assessmentQuestionnaireApiHandler';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: QuestionnaireState = {
  chosenQuestionnaire: null,
  currentQuestionIndex: 0,
  answers: [],
  questionnaire: null,
  isBreakthrough: false,
  navigationStep: 'scoring',
  createdAssessmentDetails: null,
};

const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    setChosenQuestionnaire: (
      state,
      action: PayloadAction<CompanyQuestionnaireDataInterface | null>,
    ) => ({
      ...state,
      chosenQuestionnaire: action.payload,
    }),
    setQuestionnaireQuestions: (state, action: PayloadAction<QuestionnaireInterface | null>) => ({
      ...state,
      questionnaire: action.payload,
      currentQuestionIndex: 0,
      answers: [],
      isBreakthrough: false,
    }),

    answerQuestion: (state, action: PayloadAction<{ questionId: number; answerId: number }>) => {
      const currentQuestion =
        state.questionnaire?.questionnairesV2Questions[state.currentQuestionIndex];

      return {
        ...state,
        answers: {
          ...state.answers,
          [action.payload.questionId]: {
            questionId: action.payload.questionId,
            answerId: action.payload.answerId,
            answerScore:
              currentQuestion?.questionnairesV2QuestionResponseScales.questionnairesV2QuestionResponseScaleOptions.find(
                (option) => option.id === action.payload.answerId,
              )?.score || 0,
          },
        },
        isBreakthrough: currentQuestion ? currentQuestion.isBrakeOff : false,
        currentQuestionIndex:
          state.currentQuestionIndex <
          (state.questionnaire?.questionnairesV2Questions.length ?? 0) - 1
            ? state.currentQuestionIndex + 1
            : state.currentQuestionIndex,
      };
    },

    nextQuestion: (state) => {
      if (
        state.questionnaire &&
        state.currentQuestionIndex < state.questionnaire.questionnairesV2Questions.length - 1
      ) {
        const newIndex = state.currentQuestionIndex + 1;

        return {
          ...state,
          currentQuestionIndex: newIndex,
          isBreakthrough: state.questionnaire.questionnairesV2Questions[newIndex].isBrakeOff,
        };
      }

      return state;
    },

    previousQuestion: (state) => {
      if (state.currentQuestionIndex > 0) {
        const newIndex = state.currentQuestionIndex - 1;

        return {
          ...state,
          currentQuestionIndex: newIndex,
          isBreakthrough:
            state.questionnaire?.questionnairesV2Questions[newIndex]?.isBrakeOff ?? false,
        };
      }

      return state;
    },

    setNavigationStep: (state, action: PayloadAction<AssessmentNavigationTypes>) => ({
      ...state,
      navigationStep: action.payload,
    }),

    setCreatedUserAssessment: (state, action: PayloadAction<UserAssessmentResponse>) => ({
      ...state,
      createdAssessmentDetails: action.payload,
    }),

    resetQuestionnaire: () => initialState, // Resets to initial state
  },
});

interface QuestionnaireState {
  chosenQuestionnaire: CompanyQuestionnaireDataInterface | null;
  currentQuestionIndex: number;
  answers: UserAnswer[] | [];
  questionnaire: QuestionnaireInterface | null;
  isBreakthrough: boolean;
  navigationStep: AssessmentNavigationTypes;
  createdAssessmentDetails: UserAssessmentResponse | null;
}

export type AssessmentNavigationTypes =
  | 'scoring'
  | 'ewsTrigger'
  | 'talkToAdvisor'
  | 'reflection'
  | 'complete';

interface UserAnswer {
  questionId: number;
  answerId: number;
  answerScore: number;
}

export const {
  answerQuestion,
  nextQuestion,
  previousQuestion,
  resetQuestionnaire,
  setQuestionnaireQuestions,
  setChosenQuestionnaire,
  setNavigationStep,
  setCreatedUserAssessment,
} = questionnaireSlice.actions;

export default questionnaireSlice;
