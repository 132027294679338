import { createSlice } from '@reduxjs/toolkit';

export interface UserStatusState {
  loading: boolean;
  isLoaded: boolean;
  error: string | null;
  data: {
    id: number | null;
    userId: number | null;
    isRegistered: boolean | null;
    isJoined: boolean | null;
    isAgreed: boolean | null;
    isEligibleForSpeakUpAlerts: boolean | null;
    isOnboardCompleted: boolean | null;
    isFirstShortStarCompleted: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
}

const InitialStatus = {
  id: 0,
  userId: 0,
  isRegistered: false,
  isJoined: false,
  isAgreed: false,
  isEligibleForSpeakUpAlerts: false,
  isOnboardCompleted: false,
  isFirstShortStarCompleted: false,
  createdAt: '',
  updatedAt: '',
};

const initialState: UserStatusState = {
  loading: false,
  error: null,
  isLoaded: false,
  data: InitialStatus,
};

export interface UpdateUserStatusAction {
  type: string;
  payload: {
    isRegistered?: boolean;
    isJoined?: boolean | null;
    isEligibleForSpeakUpAlerts?: boolean | null;
    isOnboardCompleted?: boolean | null;
    isAgreed?: boolean | null;
    isFirstShortStarCompleted?: boolean | null;
  };
}

export interface SetLoadingAction {
  type: string;
  payload: {
    loading: boolean;
  };
}

export interface SetErrorAction {
  type: string;
  payload: {
    error: string;
  };
}

export type UserStatusAction = SetLoadingAction | SetErrorAction | UpdateUserStatusAction;

const UserStatusSlice = createSlice({
  name: 'userStatus',
  initialState,
  reducers: {
    setLoading(state: UserStatusState, action: SetLoadingAction): UserStatusState {
      return {
        ...state,
        loading: action.payload.loading,
        isLoaded: false,
        error: null,
        data: InitialStatus,
      };
    },
    setError(state: UserStatusState, action: SetErrorAction): UserStatusState {
      return {
        ...state,
        loading: false,
        isLoaded: false,
        error: action.payload.error,
        data: InitialStatus,
      };
    },
    updateUserStatus(state: UserStatusState, action: UpdateUserStatusAction): UserStatusState {
      const { isRegistered, isJoined, isAgreed, isOnboardCompleted, isFirstShortStarCompleted } =
        action.payload;

      return {
        ...state,
        loading: false,
        isLoaded: true,
        error: null,
        data: {
          isRegistered: isRegistered !== undefined ? isRegistered : state.data.isRegistered,
          isJoined: isJoined !== undefined ? isJoined : state.data.isJoined,
          isAgreed: isAgreed !== undefined ? isAgreed : state.data.isAgreed,
          isOnboardCompleted:
            isOnboardCompleted !== undefined ? isOnboardCompleted : state.data.isOnboardCompleted,
          id: state.data.id,
          userId: state.data.userId,
          isEligibleForSpeakUpAlerts: state.data.isEligibleForSpeakUpAlerts,
          isFirstShortStarCompleted:
            isFirstShortStarCompleted !== undefined
              ? isFirstShortStarCompleted
              : state.data.isFirstShortStarCompleted,
          createdAt: state.data.createdAt,
          updatedAt: state.data.updatedAt,
        },
      };
    },
  },
});

export const userStatusActions = UserStatusSlice.actions;

export default UserStatusSlice;
