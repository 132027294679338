import NoPreviewImage from '@assets/images/no-preview.png';
import { CircularProgress } from '@mui/material';
import TextField, {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldVariants,
} from '@mui/material/TextField';
import { AutoComplete, MotionImage } from '@shared/atoms';
import { decodeHTMLEntities } from '@utils/general.util';
import React from 'react';
import styles from './index.module.scss';

interface LearningOption {
  value: number;
  imageUrl: string | null;
  label: string;
  type: string | null;
}

interface Props {
  learningContentOptions: LearningOption[];
  searchQuery: string;
  searchLoading: boolean;
  onSearchQueryChange: (query: string) => void;
  onSelectionChange: (value: number, query: string, type: string | null) => void;
}

export const LearningContentAutocomplete: React.FC<Props> = ({
  learningContentOptions,
  searchQuery,
  searchLoading,
  onSearchQueryChange,
  onSelectionChange,
}) => (
  <AutoComplete
    fullWidth
    handleHomeEndKeys
    filterOptions={(x: any) => x}
    loading={searchLoading}
    className={styles.searchInput}
    options={learningContentOptions}
    getOptionLabel={(option: { label: string }) => decodeHTMLEntities(option.label)}
    inputValue={searchQuery}
    onOpen={() => onSearchQueryChange('')}
    onClose={() => onSearchQueryChange('')}
    onInputChange={(_: any, value: string) => {
      onSearchQueryChange(value);
    }}
    onChange={(_: any, value: LearningOption | null) =>
      onSelectionChange(value?.value || 0, searchQuery, value?.type || null)
    }
    renderInput={(
      params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined } & Omit<
          FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps,
          'variant'
        >,
    ) => (
      <TextField
        {...params}
        label="Search learning content"
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {searchLoading ? (
                <CircularProgress color="inherit" size={20} sx={{ color: '#019df2' }} />
              ) : null}
              {params.InputProps?.endAdornment}
            </>
          ),
        }}
      />
    )}
    renderOption={(
      props: JSX.IntrinsicAttributes &
        React.ClassAttributes<HTMLLIElement> &
        React.LiHTMLAttributes<HTMLLIElement>,
      option: { imageUrl: string; label: string },
    ) => (
      <li {...props} className={styles.learningSearchList}>
        {option.imageUrl ? (
          <MotionImage
            src={option.imageUrl}
            alt={option.label}
            className={styles.autocompleteImageOption}
            onError={(e) => {
              e.currentTarget.src = NoPreviewImage;
            }}
            skeletonHeight="60px"
            skeletonWidth="100px"
            shape="rounded"
          />
        ) : (
          <div className={styles.autocompleteImageOption} />
        )}
        <div className={styles.contentAutocompleteOption}>
          <h6 dangerouslySetInnerHTML={{ __html: option.label }} />
        </div>
      </li>
    )}
  />
);
