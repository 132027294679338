import { UpdateUserStatusPayload, userStatusApiHandler } from '@api/userStatusApiHandler';
import { AppDispatch } from '@hooks/useAppDispatch';
import { userStatusActions } from 'store/Slices/UserStatusSlice';

export const getUserStatusData = () => async (dispatch: AppDispatch) => {
  dispatch(userStatusActions.setLoading({ loading: true }));

  try {
    const response = await userStatusApiHandler.getUserStatus();
    const status = response?.success ? response?.responseObject : null;
    const user = localStorage.getItem('user');

    if (status && user) {
      dispatch(
        userStatusActions.updateUserStatus({
          isRegistered: status.isRegistered,
          isJoined: status.isJoined,
          isAgreed: JSON.parse(user).isAgree,
          isOnboardCompleted: status.isOnboardCompleted,
        }),
      );
    } else {
      dispatch(userStatusActions.setError({ error: 'Loading user status failed' }));
    }
  } catch (error) {
    dispatch(userStatusActions.setError({ error: 'Loading user status failed' }));
  }
};

export const updateUserStatusData =
  (data: UpdateUserStatusPayload) => async (dispatch: AppDispatch) => {
    dispatch(userStatusActions.setLoading({ loading: true }));

    try {
      const user = localStorage.getItem('user');
      const userId = user && JSON.parse(user).id;

      if (user && userId) {
        const response = await userStatusApiHandler.updateUserStatus(data);
        const updatedData = response.responseObject;

        dispatch(
          userStatusActions.updateUserStatus({
            isRegistered: updatedData.isRegistered,
            isJoined: updatedData.isJoined,
            isAgreed: JSON.parse(user).isAgree,
            isEligibleForSpeakUpAlerts: updatedData.isEligibleForSpeakupAlerts,
            isOnboardCompleted: updatedData.isOnboardCompleted,
            isFirstShortStarCompleted: updatedData.isFirstShortStarCompleted,
          }),
        );
      } else {
        dispatch(userStatusActions.setError({ error: 'Update user status failed' }));
      }
    } catch (error) {
      dispatch(userStatusActions.setError({ error: 'Update user status failed' }));
    }
  };
