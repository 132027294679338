import { Middleware, configureStore } from '@reduxjs/toolkit';
import questionnaireSlice from 'store/Slices/QuestionnaireSliceV2';
import CompanyConfigSlice from './Slices/CompanyConfigSlice';
import DeepDiveWizardSlice from './Slices/DeepDiveWizardSlice';
import ExpertSlice from './Slices/ExpertSlice';
import HelpAndSpeakupSlice from './Slices/HelpAndSpeakupSlice';
import InstantHelpSlice from './Slices/InstantHelpSlice';
import RefreshConfigurationSlice from './Slices/RefreshCofigurationsSlice';
import ShortStarQuestionsSlice from './Slices/ShortStarSlice';
import ShortStarWizardSlice from './Slices/ShortStarWizardSlice';
import StarScoresSlice from './Slices/StarScoreSlice';
import UserStatusSlice from './Slices/UserStatusSlice';
import ConfigurationSlice from './Slices/configurationsSlice';

// Helper function to compare two states and log changes
const getChangedState = (prevState: any, nextState: any) => {
  const changes: any = {};

  Object.keys(nextState).forEach((key) => {
    if (JSON.stringify(prevState[key]) !== JSON.stringify(nextState[key])) {
      changes[key] = { prev: prevState[key], next: nextState[key] };
    }
  });

  return changes;
};

const loggerMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  // const isDevelopment =
  //   window.location.hostname === 'localhost' ||
  //   window.location.origin.includes('dev.platform.hapstar.app');

  // ENABLED ONLY FOR LOCALHOST
  const isDevelopment = window.location.hostname === 'localhost';

  if (isDevelopment) {
    const previousState = storeAPI.getState();

    const result = next(action);

    const nextState = storeAPI.getState();
    const changes = getChangedState(previousState, nextState);

    if (Object.keys(changes).length > 0) {
      console.groupCollapsed(`Redux Action: ${action.type}`);
      console.log('Action:', action);
      console.log('Changed State:', changes);
      console.groupEnd();
    }

    return result;
  }

  return next(action);
};

const store = configureStore({
  reducer: {
    userStatus: UserStatusSlice.reducer,
    instantHelp: InstantHelpSlice.reducer,
    companyConfig: CompanyConfigSlice.reducer,
    helpConfig: HelpAndSpeakupSlice.reducer,
    shortStarQuestions: ShortStarQuestionsSlice.reducer,
    shortStarWizard: ShortStarWizardSlice.reducer,
    configurations: ConfigurationSlice.reducer,
    starScores: StarScoresSlice.reducer,
    deepDiveWizard: DeepDiveWizardSlice.reducer,
    experts: ExpertSlice.reducer,
    refreshConfigurations: RefreshConfigurationSlice.reducer,

    // V2 starts here
    questionnaireV2: questionnaireSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(loggerMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
