import moreActive from '@assets/images/icons/more-menu-active.svg';
import moreInactive from '@assets/images/icons/more-menu-inactive.svg';
import menuConfig from '@config/menu-v2.config';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { useAppSelector } from '@hooks/useAppSelector';
import React, { useContext } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { NavLink, useLocation } from 'react-router-dom';
import { RootState } from 'store';
import { ConfigurationState } from 'store/Slices/CompanyConfigSlice';
import styles from './index.module.scss';

export const MobileFooter: React.FunctionComponent<FooterProps> = (props) => {
  const { navigationType } = props;
  const { toggled, toggleSidebar } = useProSidebar();
  const { pathname } = useLocation();
  const { toggleSubNav } = useContext(SecondaryNavigationContext);
  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig);
  const defaultSubNav = { id: 1, name: 'Overall' };

  const handleClick = () => {
    toggleSidebar(!toggled);
  };

  const getFilteredMenu = (menuItems: any[], companyConfig: ConfigurationState) =>
    menuItems.filter((item) => {
      if (!companyConfig.data.showInstantHelp && item.name === 'Hub') {
        return false;
      }

      if (!companyConfig.data.expertsEnabled && item.name === 'Experts') {
        return false;
      }

      return true;
    });

  const selectedMenuItems = getFilteredMenu(
    (navigationType && menuConfig[navigationType]) || menuConfig.default,
    companyConfigData,
  );

  return (
    <div className={styles.hsMobileFooter}>
      <ul>
        {selectedMenuItems.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              if (item.name === 'Scores')
                toggleSubNav(JSON.parse(sessionStorage.getItem('subNav') as any) || defaultSubNav);
            }}
          >
            <li key={index}>
              <NavLink to={item.path} {...(toggled && { onClick: handleClick })}>
                <img
                  src={
                    pathname === item.path
                      ? item.activeIcon
                      : (pathname?.includes('/post') && item.name === 'Learn') ||
                        (pathname.includes('/expert') && item.name === 'Experts')
                      ? item.activeIcon
                      : item.icon
                  }
                  alt={item.name}
                />
                {item.mobileName}
              </NavLink>
            </li>
          </div>
        ))}
        <li className={styles.moreMenuIcon}>
          <a onClick={handleClick}>
            <img src={!toggled ? moreInactive : moreActive} alt="Menu" />
          </a>
        </li>
      </ul>
    </div>
  );
};

MobileFooter.defaultProps = {
  navigationType: 'default',
};

interface FooterProps {
  navigationType?: 'default' | 'admin' | 'superAdmin' | 'advisor';
}
