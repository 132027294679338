import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './index.module.scss';

interface AutocompleteProps {
  options: Array<string | { label: string; value: any }>;
  label?: string;
  isError?: boolean;
  className?: string;
  renderInputProps?: any;
  [key: string]: any;
}

export const AutoComplete: FunctionComponent<AutocompleteProps> = (props) => {
  const { options, label, isError, className, renderInputProps, ...rest } = props;

  return (
    <Autocomplete
      options={options}
      className={classNames([className, isError && styles.error])}
      renderInput={(params) => (
        <TextField {...params} label={label} error={isError} {...renderInputProps} />
      )}
      sx={{
        '& .MuiOutlinedInput-root': {
          border: '1px solid #f4f2ec',
          borderRadius: '10px',
          padding: '5px 5px 10px 10px',
          backgroundColor: '#fff',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #eee',
        },
      }}
      {...rest}
    />
  );
};

AutoComplete.displayName = 'AutocompleteComponent';

AutoComplete.defaultProps = {
  label: '',
  isError: false,
  className: styles.input,
  renderInputProps: {},
};
