import React from 'react';
import styles from './index.module.scss';

export const ColoredBox: React.FunctionComponent<ColoredBoxType> = (props) => {
  const { title, color, children, isHomePage, isTitleCentered } = props;
  const className = `${styles.hsColoredBox} ${styles[color]}`;

  return (
    <div className={className} style={{ height: isHomePage ? '700px' : undefined }}>
      <p
        className={styles.hsColoredBoxTitle}
        style={isTitleCentered ? { textAlign: 'center' } : {}}
      >
        {title}{' '}
      </p>
      {children}
    </div>
  );
};

ColoredBox.defaultProps = {
  isHomePage: false,
  isTitleCentered: false,
};

type ColoredBoxType = {
  title: string;
  color: string;
  children: React.ReactNode;
  isHomePage?: boolean;
  isTitleCentered?: boolean;
};
