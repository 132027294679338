import useWindowDimensions from '@hooks/useWindowDimensions';
import { LoadingDots, MotionText } from '@shared/atoms';
import { motion } from 'framer-motion';
import React from 'react';
import styles from './index.module.scss';

const ZoomText: React.FC<{ text: string }> = ({ text }) => (
  <MotionText
    as="h1"
    className={styles.zoomText}
    initial={{ scale: 0.8, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    exit={{ scale: 0.8, opacity: 0 }}
    transition={{ duration: 0.6, ease: 'easeOut' }}
    text={text}
  />
);

export const AssessmentLoadingScreen: React.FC<{ loading: boolean }> = ({ loading }) => {
  const { isDesktop } = useWindowDimensions();

  return (
    <motion.div
      className={styles.loader}
      initial={{ scale: 0.9, opacity: 0.8 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.8, ease: 'easeInOut' }}
    >
      <div>
        <ZoomText text="Getting Things Ready..." />
      </div>

      <LoadingDots
        size={isDesktop ? 16 : 25}
        marginTop={isDesktop ? '50px' : '60%'}
        isHapstarLoading
        dotCount={isDesktop ? 5 : 3}
      />
    </motion.div>
  );
};
